import React from 'react';
import PropTypes from 'prop-types';
import reactCSS from 'reactcss';

function getMargin(align, reverse = false) {
  let names = ['left', 'center', 'right'];
  const values = ['0', 'auto', 'auto'];

  if (reverse) {
    names = names.reverse();
  }

  const ix = names.indexOf(align);
  return values[ix];
}

function TableHeadObject({ tableHead }) {
  return (
    <tr>
      {tableHead.split('\n').map((item) => (<th>{item}</th>))}
    </tr>
  );
}

function TableRowObject({ tableRow }) {
  return (
    <>
      {tableRow.split('\n\n').map((item) => (
        <tr>
          {item.split('\n').map((lol) => (<td>{lol}</td>))}
        </tr>
      ))}
    </>
  );
}

TableHeadObject.propTypes = {
  tableHead: PropTypes.string.isRequired,
};
TableRowObject.propTypes = {
  tableRow: PropTypes.string.isRequired,
};

function Table({ item }) {
  const { content } = item;

  const styles = reactCSS({
    default: {
      table: {
        marginLeft: getMargin(content.style.align),
        marginRight: getMargin(content.style.align, true),
        width: `${content.style.width}%`,
        textAlign: content.style.align,
        fontSize: `${content.style.fontSize}em`,
        lineHeight: `${content.style.lineHeight}em`,
      },
    },
  });

  if (typeof item === 'undefined') {
    return <ul>Undefined</ul>;
  }

  return (
    <table className="table" style={styles.table}>
      <thead>
        <TableHeadObject tableHead={content.tableHead} />
      </thead>
      <tbody>
        <TableRowObject tableRow={content.tableRow} />
      </tbody>
    </table>

  );
}

Table.propTypes = {
  item: PropTypes.string.isRequired,
};

export default Table;
