import React from 'react';
import PropTypes from 'prop-types';
import { convertToRaw, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { stateFromHTML } from 'draft-js-import-html';
import { stateToHTML } from 'draft-js-export-html';

import toolbar from './toolbar';

export default function Wysiwyg({ item, onChange }) {
  if (typeof item === 'undefined' || (item.content && !item.content.html)) {
    if (item.content && !item.content.html) {
      // migrate existing html stuff
      onChange({
        ...item,
        content: {
          html: item.content || '',
        },
      });
    }

    return <div />;
  }

  const updateEditor = (data) => {
    const html = stateToHTML(convertFromRaw(data));
    onChange({
      ...item,
      content: {
        ...item.content,
        html: html || '',
      },
    });
  };

  return (
    <div style={{ fontSize: '16px' }}>
      <Editor
        defaultContentState={convertToRaw(stateFromHTML(item.content.html || ''))}
        onChange={updateEditor}
        toolbar={toolbar}
      />
    </div>
  );
}

Wysiwyg.propTypes = {
  item: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
