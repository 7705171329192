import React from 'react';
import PropTypes from 'prop-types';

export default class Contactform extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
  }

  onChange(content) {
    const { item, onChange } = this.props;

    onChange({
      ...item,
      content,
    });
  }

  render() {
    const { item } = this.props;

    const nitem = {
      content: {},
      ...item,
    };

    if (typeof nitem === 'undefined') {
      return <div />;
    }

    return (
      <div>
        <h3>Kontaktformulär kommer synas här</h3>
        <input
          style={{ visibility: 'hidden' }}
          className="form-control"
          defaultValue={nitem.content.url}
          onChange={(e) => {
            this.onChange({ ...nitem.content, url: e.target.value });
          }}
        />
      </div>
    );
  }
}

Contactform.propTypes = {
  item: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};
