import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ChromePicker } from 'react-color';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import FontPicker from '../../../../components/font-picker';
import { BlockSizeDropdown } from '../../../components/BlockSizeDropdown';
import { BlockPlacingDropdown } from '../../../components/BlockPlacingDropdown';
import BlockClassesField from '../../../components/BlockClassesField';

export default class TitleProperties extends Component {
  static defaultContent() {
    return {
      text: 'Hello',
      style: {
        color: {
          r: 51,
          g: 51,
          b: 51,
          a: 1,
        },
        fontFamily: {
          source: 'system',
          name: 'Standard',
          value: 'inherit',
        },
        fontSize: 1.5,
        textAlign: 'left',
        letterSpacing: 0,
        fontWeight: 400,
      },
    };
  }

  constructor(props) {
    super(props);

    this.handleTextChange = this.handleTextChange.bind(this);
    this.handleLinkChange = this.handleLinkChange.bind(this);
    this.handleColorChange = this.handleColorChange.bind(this);
    this.handleFontChange = this.handleFontChange.bind(this);
    this.handleSizeChange = this.handleSizeChange.bind(this);
    this.handleAlignChange = this.handleAlignChange.bind(this);
    this.handleLetterSpacingChange = this.handleLetterSpacingChange.bind(this);
    this.handleBlockSizeChange = this.handleBlockSizeChange.bind(this);
    this.handleBlockPlacingChange = this.handleBlockPlacingChange.bind(this);
    this.changeFontWeightChange = this.changeFontWeightChange.bind(this);
  }

  handleTextChange(event) {
    const diff = {
      content: {
        text: event.target.value,
      },
    };
    const { onChange } = this.props;
    onChange(diff);
  }

  handleLinkChange(event) {
    const diff = {
      content: {
        link: event.target.value,
      },
    };
    const { onChange } = this.props;
    onChange(diff);
  }

  handleColorChange(color) {
    const diff = {
      content: {
        style: {
          color: color.rgb,
        },
      },
    };
    const { onChange } = this.props;
    onChange(diff);
  }

  handleFontChange(fontFamily) {
    const diff = {
      content: {
        style: {
          fontFamily,
        },
      },
    };
    const { onChange } = this.props;
    onChange(diff);
  }

  handleSizeChange(fontSize) {
    const diff = {
      content: {
        style: {
          fontSize,
        },
      },
    };
    const { onChange } = this.props;
    onChange(diff);
  }

  handleAlignChange(event) {
    const diff = {
      content: {
        style: {
          textAlign: event.target.value,
        },
      },
    };
    const { onChange } = this.props;
    onChange(diff);
  }

  handleLetterSpacingChange(letterSpacing) {
    const diff = {
      content: {
        style: {
          letterSpacing,
        },
      },
    };
    const { onChange } = this.props;
    onChange(diff);
  }

  handleBlockSizeChange(size) {
    const diff = {
      size,
    };
    const { onChange } = this.props;
    onChange(diff);
  }

  handleBlockPlacingChange(placing) {
    const diff = {
      placing,
    };
    const { onChange } = this.props;
    onChange(diff);
  }

  changeFontWeightChange(fontWeight) {
    const diff = {
      content: {
        style: {
          fontWeight,
        },
      },
    };
    const { onChange } = this.props;
    onChange(diff);
  }

  render(TextType = 'input', allowLinks = false) {
    const {
      size, placing, content, classes, onChange,
    } = this.props;

    return (
      <div>
        <BlockSizeDropdown defaultValue={size} onChange={this.handleBlockSizeChange} />
        <BlockPlacingDropdown defaultValue={placing} onChange={this.handleBlockPlacingChange} />
        <BlockClassesField defaultValue={classes} onChange={onChange} />

        <div className="field">
          <label htmlFor="content-text">Text</label>
          <TextType
            id="content-text"
            className="form-control"
            type="text"
            value={content.text}
            onChange={this.handleTextChange}
          />
        </div>

        <div className="field">
          <label htmlFor="prop-color">Färg</label>
          <ChromePicker
            id="prop-color"
            color={content.style.color}
            onChangeComplete={this.handleColorChange}
          />
        </div>

        <div className="field">
          <label htmlFor="prop-font-family">Typsnitt</label>
          <FontPicker
            id="prop-font-family"
            fontFamily={content.style.fontFamily}
            onChange={this.handleFontChange}
          />
        </div>

        <div className="field">
          <label htmlFor="prop-font-size">Text storlek</label>
          <Slider
            id="prop-font-size"
            min={0.8}
            max={10}
            value={content.style.fontSize}
            step={0.1}
            onChange={this.handleSizeChange}
          />
        </div>

        <div className="field">
          <label htmlFor="prop-text-align">Justering</label>
          <select
            id="prop-text-align"
            value={content.style.textAlign}
            className="form-control"
            onChange={this.handleAlignChange}
          >
            <option value="left">Vänster</option>
            <option value="center">Centrerad</option>
            <option value="right">Höger</option>
          </select>
        </div>

        <div className="field">
          <label htmlFor="prop-letter-spacing">Tackenavstånd</label>
          <Slider
            id="prop-letter-spacing"
            min={-0.5}
            max={1.5}
            value={content.style.letterSpacing}
            step={0.05}
            onChange={this.handleLetterSpacingChange}
          />
        </div>

        <div className="field">
          <label htmlFor="prop-font-weight">Text vikt</label>
          <Slider
            id="prop-font-weight"
            min={100}
            max={900}
            value={content.style.fontWeight}
            step={100}
            onChange={this.changeFontWeightChange}
          />
        </div>

        {allowLinks
          ? (
            <div className="field">
              <label htmlFor="content-link">Link (lämna tom om ingen)</label>
              <input
                id="content-link"
                className="form-control"
                type="text"
                value={content.link}
                onChange={this.handleLinkChange}
              />
            </div>
          ) : null}

      </div>
    );
  }
}

TitleProperties.propTypes = {
  onChange: PropTypes.func.isRequired,
  size: PropTypes.number.isRequired,
  placing: PropTypes.number.isRequired,
  classes: PropTypes.string.isRequired,
  content: PropTypes.shape({
    text: PropTypes.string.isRequired,
    link: PropTypes.string,
    style: PropTypes.shape({
      color: PropTypes.shape({
        r: PropTypes.number.isRequired,
        g: PropTypes.number.isRequired,
        b: PropTypes.number.isRequired,
        a: PropTypes.number.isRequired,
      }).isRequired,
      fontSize: PropTypes.number.isRequired,
      fontFamily: PropTypes.shape({
        source: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      }),
      textAlign: PropTypes.string.isRequired,
      letterSpacing: PropTypes.number.isRequired,
      fontWeight: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
};
