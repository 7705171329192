import React from 'react';
import PropTypes from 'prop-types';

import MediaManager from '../support/mediamanager';

export default function Image({ item, onChange }) {
  return <MediaManager item={item} onChange={onChange} />;
}

Image.propTypes = {
  // eslint-disable-next-line
  item: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};
