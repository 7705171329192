import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Spinner from '../components/spinner';

import API from '../core/api';
// import ccc from '../core/classes';

class Home extends Component {
  componentDidMount() {
    API.fetch('websites').then((res) => this.setState({ websites: res }));
  }

  render() {
    if (!this.state) {
      return (
        <div className="cl-mcont">
          <Spinner />
        </div>
      );
    }

    const { websites } = this.state;

    return (
      <div>
        <div id="page-header" className="navbar navbar-default">
          <h2>{websites.length <= 1 ? 'Min hemsida' : 'Mina hemsidor'}</h2>
        </div>
        <div className="cl-mcont">
          <div className="row flexwraps">
            { this.state && websites && websites.map((item) => (
              <div className="col-sm-4 col-md-4" key={item.id}>
                <div className="block-flat">
                  <div className="header">
                    <h3>{item.name}</h3>
                  </div>
                  <div className="content">
                    <strong>Finns på följande URLer:</strong>
                    <ul>
                      { item.urls && item.urls.map((url) => (
                        <li key={url.id}><a className="externalLink" href={`http://${url.url}`} target="_blank" rel="noreferrer">{url.url}</a></li>
                      )) }
                    </ul>
                    <Link to={`/website/${item.id}/`} className="btn btn-default">Redigera</Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
