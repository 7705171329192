import React from 'react';
import PropTypes from 'prop-types';

import API from '../../../core/api';
import Spinner from '../../../components/spinner';

import './logotypemanager.css';

export default class LogotypeManager extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };

    this.fileInput = React.createRef();

    this.onUpload = this.onUpload.bind(this);
  }

  onUpload(e) {
    const files = Array.from(e.target.files);
    const { website, onChange } = this.props;

    this.setState({ loading: true });

    files.forEach((f) => {
      const formData = new FormData();

      formData.set('file', f);

      API.fetch(`websites/${website.id}/logotype`, 'POST', formData).then((result) => {
        if (!result.message) { // This is an error
          onChange({
            website: {
              ...website,
              logotype: result,
            },
          });
        }

        this.setState({ loading: false });
      });
    });
  }

  render() {
    const { website } = this.props;
    const { loading } = this.state;
    const media = website.logotype;

    if (typeof website === 'undefined') {
      return <div />;
    }

    if (loading) {
      return (
        <div className="logotypemanager">
          <Spinner padding="20px" />
        </div>
      );
    }

    return (
      <div className="logotypemanager">
        <div className="mediaitem" key={media.id} style={{ backgroundImage: `url(${media.url})` }} alt={media.name} />
        <button type="button" className="mediaitem btn" onClick={() => this.fileInput.current.click()}>
          <i className="fa fa-upload" />
          <br />
          Ladda upp
        </button>
        <input ref={this.fileInput} className="fileinput" type="file" id="fileuploader" onChange={this.onUpload} />
      </div>
    );
  }
}

LogotypeManager.propTypes = {
  website: PropTypes.shape({
    id: PropTypes.number.isRequired,
    nav: PropTypes.string.isRequired,
    logotype: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};
