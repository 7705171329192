import React from 'react';
import PropTypes from 'prop-types';
import Text from '../abstract/text/text';

export default class Paragraph extends Text {
  render() {
    const { item } = this.props;
    const { id, content } = item;

    Paragraph.updateFontLink(id, content);

    const styles = Paragraph.getStyle(content);

    if (typeof item === 'undefined') {
      return <div />;
    }

    return (
      <p style={styles.text}>
        {content.text.split('\n').map((text) => (
          <span key={text}>
            {text}
            <br />
          </span>
        ))}
      </p>
    );
  }
}

Paragraph.propTypes = {
  item: PropTypes.string.isRequired,
};
