import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Slider from 'rc-slider';

export default class CountDownProperties extends Component {
  static defaultContent() {
    return {
      endDate: new Date(new Date().getTime() + (5 * 24 * 60 * 60 * 1000)),
      width: '100',
      align: 'center',
      numberSize: '50',
      textSize: '13',
      distance: '10',
      msg: 'Lets Party!',
    };
  }

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleAlignChange = this.handleAlignChange.bind(this);
    this.handleSliderChange = this.handleSliderChange.bind(this);
    this.handleTextSizeSliderChange = this.handleTextSizeSliderChange.bind(this);
    this.handleNumberSizeSliderChange = this.handleNumberSizeSliderChange.bind(this);
    this.handleDistanceSliderChange = this.handleDistanceSliderChange.bind(this);
    this.handleMsgChange = this.handleMsgChange.bind(this);
  }

  handleChange(e) {
    const diff = {
      content: {
        endDate: e.target.value,
      },
    };
    const { onChange } = this.props;
    onChange(diff);
  }

  handleAlignChange(e) {
    const diff = {
      content: {
        align: e.target.value,
      },
    };
    const { onChange } = this.props;
    onChange(diff);
  }

  handleSliderChange(width) {
    const diff = {
      content: {
        width,
      },
    };
    const { onChange } = this.props;
    onChange(diff);
  }

  handleTextSizeSliderChange(textSize) {
    const diff = {
      content: {
        textSize,
      },
    };
    const { onChange } = this.props;
    onChange(diff);
  }

  handleNumberSizeSliderChange(numberSize) {
    const diff = {
      content: {
        numberSize,
      },
    };
    const { onChange } = this.props;
    onChange(diff);
  }

  handleDistanceSliderChange(distance) {
    const diff = {
      content: {
        distance,
      },
    };
    const { onChange } = this.props;
    onChange(diff);
  }

  handleMsgChange(e) {
    const diff = {
      content: {
        msg: e.target.value,
      },
    };
    const { onChange } = this.props;
    onChange(diff);
  }

  render() {
    const { content } = this.props;
    return (
      <div>
        Datum:&nbsp;
        <input
          type="text"
          value={content.endDate}
          onChange={this.handleChange}
        />
        <br />
        <br />
        Justering&nbsp;
        <select
          value={content.align}
          onChange={this.handleAlignChange}
        >
          <option value="left">Vänster</option>
          <option value="center">Centrerad</option>
          <option value="right">Höger</option>
        </select>
        <br />
        <br />
        Bredd:
        {content.width}
        %&nbsp;
        <Slider
          min={1}
          max={100}
          step={1}
          defaultValue={content.width}
          onChange={this.handleSliderChange}
        />
        <br />
        <br />
        Storlek på siffrorna:
        {content.numberSize}
        px&nbsp;
        <Slider
          min={1}
          max={100}
          step={1}
          defaultValue={content.numberSize}
          onChange={this.handleNumberSizeSliderChange}
        />
        <br />
        <br />
        Storlek på Texten:
        {content.textSize}
        px&nbsp;
        <Slider
          min={1}
          max={100}
          step={1}
          defaultValue={content.textSize}
          onChange={this.handleTextSizeSliderChange}
        />
        <br />
        <br />
        Distans nummer/text:
        {content.distance}
        px&nbsp;
        <Slider
          min={1}
          max={100}
          step={1}
          defaultValue={content.distance}
          onChange={this.handleDistanceSliderChange}
        />
        <br />
        <br />
        Meddalande vid mål:&nbsp;
        <input
          type="text"
          value={content.msg}
          onChange={this.handleMsgChange}
        />
      </div>
    );
  }
}

CountDownProperties.propTypes = {
  onChange: PropTypes.func.isRequired,
  content: PropTypes.shape({
    endDate: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
    align: PropTypes.string.isRequired,
    numberSize: PropTypes.number.isRequired,
    textSize: PropTypes.number.isRequired,
    distance: PropTypes.number.isRequired,
    msg: PropTypes.string.isRequired,
  }).isRequired,
};
