import React from 'react';
import PropTypes from 'prop-types';
import reactCSS from 'reactcss';

function Space({ item }) {
  const { content } = item;

  const styles = reactCSS({
    default: {
      space: {
        height: `${content.style.height}px`,
        width: '100%',
      },
    },
  });

  if (typeof item === 'undefined') {
    return <div />;
  }

  return (
    <div style={styles.space} />
  );
}

Space.propTypes = {
  item: PropTypes.string.isRequired,
};

export default Space;
