import React from 'react';
import PropTypes from 'prop-types';
import reactCSS from 'reactcss';

function getMargin(align, reverse = false) {
  let names = ['left', 'center', 'right'];
  const values = ['0', 'auto', 'auto'];

  if (reverse) {
    names = names.reverse();
  }

  const ix = names.indexOf(align);
  return values[ix];
}

function ListObject({ list, style }) {
  return (
    <>
      {list.split('\n').map((item) => (<li key={item} style={style}>{item}</li>))}
    </>
  );
}

ListObject.propTypes = {
  list: PropTypes.string.isRequired,
  style: PropTypes.string.isRequired,
};

function List({ item }) {
  const { content } = item;

  const styles = reactCSS({
    default: {
      title: {
        marginLeft: getMargin(content.style.align),
        marginRight: getMargin(content.style.align, true),
        textAlign: content.style.align,
        width: `${content.style.width}%`,
      },
      ul: {
        fontSize: `${content.style.fontSize}em`,
        lineHeight: `${content.style.lineHeight}em`,
      },
      li: {
        listStyleType: `${content.style.listStyleType}`,
        listStyle: `${content.style.listStyleType}`,
      },
    },
  });

  if (typeof item === 'undefined') {
    return <ul>Undefined</ul>;
  }

  return (
    <div className="list-wrapper" style={styles.title}>
      <h2 className="title">
        {content.title}
      </h2>
      <ul className="ulBlock" style={styles.ul}>
        <ListObject list={content.list} style={styles.li} />
      </ul>
    </div>

  );
}

List.propTypes = {
  item: PropTypes.string.isRequired,
};

export default List;
