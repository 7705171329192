import React, { useEffect, useState } from 'react';
import reactCSS from 'reactcss';
import './countDown.css';

/* eslint-disable react/prop-types */
function CountdownTimer(props) {
  const { item } = props;
  const { content } = item;
  const calculateTimeLeft = () => {
    const diff = +new Date(content.endDate) - +new Date();
    let timeLeft = {};

    if (diff > 0) {
      timeLeft = {
        dagar: Math.floor(diff / (1000 * 60 * 60 * 24)),
        timmar: Math.floor((diff / (1000 * 60 * 60)) % 24),
        minuter: Math.floor((diff / (1000 * 60)) % 60),
        sekunder: Math.floor((diff / (1000)) % 60),
        finished: false,
      };
    } else {
      timeLeft = {
        dagar: 0,
        timmar: 0,
        minuter: 0,
        sekunder: 0,
        finished: true,
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  });

  const styles = reactCSS({
    default: {
      Countdown: {
        textAlign: `${content.align}`,
        width: `${content.width}%`,
      },
      text: {
        fontSize: `${content.textSize}px`,
        marginTop: `${content.distance}px`,
      },
      number: {
        fontSize: `${content.numberSize}px`,
      },
    },
  });

  return (
    <div style={styles.Countdown} className="Countdown">
      <span className="Countdown-col" style={{ display: timeLeft.finished === false ? 'inline-block' : 'none' }}>
        <span className="Countdown-col-element">
          <strong style={styles.number}>{timeLeft.dagar}</strong>
          <span style={styles.text}>{timeLeft.dagar === 1 ? 'Dag' : 'Dagar'}</span>
        </span>
      </span>

      <span className="Countdown-col" style={{ display: timeLeft.finished === false ? 'inline-block' : 'none' }}>
        <span className="Countdown-col-element">
          <strong style={styles.number}>{timeLeft.timmar}</strong>
          <span style={styles.text}>{timeLeft.timmar === 1 ? 'Timme' : 'Timmar'}</span>
        </span>
      </span>

      <span className="Countdown-col" style={{ display: timeLeft.finished === false ? 'inline-block' : 'none' }}>
        <span className="Countdown-col-element">
          <strong style={styles.number}>{timeLeft.minuter}</strong>
          <span style={styles.text}>Min</span>
        </span>
      </span>

      <span className="Countdown-col" style={{ display: timeLeft.finished === false ? 'inline-block' : 'none' }}>
        <span className="Countdown-col-element">
          <strong style={styles.number}>{timeLeft.sekunder}</strong>
          <span style={styles.text}>Sek</span>
        </span>
      </span>

      <span className="Countdown-col" style={{ display: timeLeft.finished === true ? 'inline-block' : 'none' }}>
        <span className="Countdown-col-element">
          <strong style={styles.number}>{content.msg}</strong>
        </span>
      </span>
    </div>
  );
}

export default CountdownTimer;
