const googleFonts = [];

const systemFonts = [{
  source: 'system',
  name: 'Standard',
  value: 'inherit',
}];

const addSystemFont = (name, value) => {
  systemFonts.push({
    source: 'system',
    name,
    value,
  });
};

const addGoogleFont = (name) => {
  googleFonts.push({
    source: 'google',
    name,
    value: name,
  });
};

const sortFonts = (fontsArray) => fontsArray.sort((a, b) => a.name.localeCompare(b.name));

addSystemFont('Arial', 'Arial, Helvetica, sans-serif');
addSystemFont('Courier New', '\'Courier New\', Courier, monospace');
addSystemFont('Georgia', 'Georgia, \'Times New Roman\', Times, serif');
addSystemFont('Impact', 'Impact, Charcoal, sans-serif');
addSystemFont('Palatino Linotype', '\'Palatino Linotype\', \'Book Antiqua\', Palatino, serif');
addSystemFont('Tahoma', 'Tahoma, Geneva, sans-serif');
addSystemFont('Times New Roman', '\'Times New Roman\', Times, serif');
addSystemFont('Verdana', 'Verdana, Geneva, sans-serif');

addGoogleFont('Roboto');
addGoogleFont('Open Sans');
addGoogleFont('Lato');
addGoogleFont('Montserrat');
addGoogleFont('Roboto Condensed');
addGoogleFont('Source Sans Pro');
addGoogleFont('Oswald');
addGoogleFont('Raleway');
addGoogleFont('Poppins');
addGoogleFont('Roboto Mono');
addGoogleFont('Noto Sans');
addGoogleFont('Merriweather');
addGoogleFont('Roboto Slab');
addGoogleFont('PT Sans');
addGoogleFont('Ubuntu');
addGoogleFont('Playfair Display');
addGoogleFont('Open Sans Condensed');
addGoogleFont('Muli');
addGoogleFont('PT Serif');
addGoogleFont('Slabo 27px');

export const SYSTEM_FONTS = systemFonts;
export const GOOGLE_FONTS = sortFonts(googleFonts);
