import React from 'react';
import PropTypes from 'prop-types';

export default function Dropdown({
  id, name, options, defaultValue, onChange,
}) {
  return (
    <div className="field">
      <label htmlFor={id}>{name}</label>
      <select
        id={id}
        value={defaultValue}
        className="form-control"
        onChange={(event) => {
          onChange(event.target.value);
        }}
      >
        {options.map((option) => <option value={option.value}>{option.name}</option>)}
      </select>
    </div>
  );
}

Dropdown.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
  defaultValue: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
