import React from 'react';
import PropTypes from 'prop-types';
import { BlockSizeDropdown } from '../../components/BlockSizeDropdown';
import Dropdown from '../../../components/dropdown/Dropdown';
import { BlockPlacingDropdown } from '../../components/BlockPlacingDropdown';
import BlockClassesField from '../../components/BlockClassesField';

const numberOfNewsItemsOpts = [
  { name: '3 st', value: '3' },
  { name: '5 st', value: '5' },
  { name: '10 st', value: '10' },
  { name: '15 st', value: '15' },
];

const presentationOpts = [
  { name: 'Rubrik och innehåll', value: 'full' },
  { name: 'Rubrik', value: 'heading' },
];

function NewslistProperties({
  onChange, size, placing, content, classes,
}) {
  const handleContentChange = (item, newValue) => {
    onChange({
      content: {
        [item]: newValue,
      },
    });
  };

  const handleBlockSizeChange = (newSize) => {
    const diff = {
      size: newSize,
    };
    onChange(diff);
  };

  const handleBlockPlacingChange = (newPlacing) => {
    const diff = {
      placing: newPlacing,
    };
    onChange(diff);
  };

  return (
    <div>
      <BlockSizeDropdown defaultValue={size} onChange={handleBlockSizeChange} />
      <BlockPlacingDropdown defaultValue={placing} onChange={handleBlockPlacingChange} />
      <BlockClassesField defaultValue={classes} onChange={onChange} />

      <Dropdown
        name="Antal nyhetsartiklar som visas"
        id="prop-newslist-numberofitems"
        options={numberOfNewsItemsOpts}
        defaultValue={content.numberOfItems}
        onChange={(newValue) => handleContentChange('numberOfItems', newValue)}
      />

      <Dropdown
        name="Presentation"
        id="prop-newslist-presentation"
        options={presentationOpts}
        defaultValue={content.presentation || 'full'}
        onChange={(newValue) => handleContentChange('presentation', newValue)}
      />
    </div>
  );
}

NewslistProperties.defaultContent = () => ({
  numberOfItems: 10,
  presentation: 'full',
});

NewslistProperties.propTypes = {
  onChange: PropTypes.func.isRequired,
  size: PropTypes.number.isRequired,
  placing: PropTypes.number.isRequired,
  classes: PropTypes.string.isRequired,
  content: PropTypes.shape({
    numberOfItems: PropTypes.string.isRequired,
    presentation: PropTypes.string.isRequired,
  }).isRequired,
};

export default NewslistProperties;
