import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { resolveBlock } from './BlockRegister';

function Block({
  value, updateBlock, deleteBlock, moveBlock, isSorting,
}) {
  const BlockItem = resolveBlock(value.type || 'html');
  const MyTag = BlockItem.display;
  const [askDelete, setAskDelete] = useState(false);

  return (
    <li>
      <div className="col-md-12">
        <div className="block-flat">
          <div className="row" style={{ borderBottom: '1px solid #F1F1F1', marginBottom: 15 }}>
            <div className="col-xs-12 col-md-8">
              {/* <input
                  type="text"
                  placeholder="Sidnamn"
                  id={`${value.id}-title`}
                  value={value.title}
                  className="form-control"
                  onChange={e => updateBlock({ ...value, title: e.target.value })}
                /> */}
              <h3 style={{ marginTop: 5 }}>
                {BlockItem.title}
                -block
              </h3>
            </div>
            <div className="pull-right">
              { askDelete ? (
                <div>
                  <span>Är du säker? </span>
                  {' '}
&nbsp;
                  <button
                    type="button"
                    className="btn btn-danger"
                    href="#"
                    onClick={(event) => {
                      event.stopPropagation();
                      return deleteBlock(value.id) && setAskDelete(false);
                    }}
                  >
                    Ta bort
                  </button>
                  <button
                    type="button"
                    className="btn btn-default"
                    href="#"
                    onClick={(event) => {
                      event.stopPropagation();
                      setAskDelete(false);
                    }}
                  >
                    Avbryt
                  </button>
                </div>
              ) : (
                <div>
                  <button
                    type="button"
                    className="btn btn-default"
                    href="#"
                    onClick={(event) => {
                      event.stopPropagation();
                      moveBlock(value, -1);
                    }}
                  >
                    <i className="fa fa-arrow-up" />
                    {' '}
                    Flytta upp
                  </button>
                  <button
                    type="button"
                    className="btn btn-default"
                    href="#"
                    onClick={(event) => {
                      event.stopPropagation();
                      moveBlock(value, +1);
                    }}
                  >
                    <i className="fa fa-arrow-down" />
                    {' '}
                    Flytta ner
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger"
                    href="#"
                    onClick={(event) => {
                      event.stopPropagation();
                      return setAskDelete(true);
                    }}
                  >
                    <i className="fa fa-times" />
                    {' '}
                    Ta bort
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className={isSorting ? '' /* 'sorting-block-collapse' */ : ''}>
            <MyTag item={value} onChange={updateBlock} />
          </div>
        </div>
      </div>
    </li>
  );
}

Block.propTypes = {
  value: PropTypes.object.isRequired,
  updateBlock: PropTypes.func.isRequired,
  deleteBlock: PropTypes.func.isRequired,
  moveBlock: PropTypes.func.isRequired,
  isSorting: PropTypes.bool,
};

Block.defaultProps = {
  isSorting: false,
};

export default Block;
