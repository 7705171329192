import React, { Component } from 'react';
import PropTypes from 'prop-types';
import extend from 'extend';
import { Link, useParams } from 'react-router-dom';

import Spinner from '../../components/spinner';

import Block from '../blocks';
import { resolvePropertiesBlock, getBlockTypes, resolveBlock } from '../blocks/BlockRegister';
import API from '../../core/api';
import MetaEditor from './metaeditor';
import { PropertyPanel } from '../../components/propertypanel';
import { BlockSize } from '../components/BlockSizeDropdown';

function parseBlock(block) {
  try {
    return {
      ...block,
      content: JSON.parse(block.content),
    };
  } catch (e) {
    return block;
  }
}

function PageSettings({ page, updateValue }) {
  return (
    <>
      <div className="inside-header">
        <h2>Redigera sidinställningar</h2>
      </div>
      <div
        className="col-md-12"
      >
        <div className="block-flat">
          <div className="row">
            <div className="col-xs-12 col-sm-6">
              <label htmlFor="pagetitle">
                Sidnamn
                <input type="text" placeholder="Sidnamn" id="pagetitle" value={page.title} onChange={updateValue} className="form-control" />
              </label>
            </div>
            <div className="col-xs-12 col-sm-6">
              <label htmlFor="pageslug">
                Beskrivande webbadress
                <input type="text" placeholder="Beskrivande webbadress" id="pageslug" value={page.slug} onChange={updateValue} className="form-control" />
              </label>
            </div>
          </div>
        </div>

        <div className="block-flat">
          <div className="row">
            <div className="col-xs-12">
              <MetaEditor meta={page.meta} onChange={updateValue} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

PageSettings.propTypes = {
  page: PropTypes.shape({
    title: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    meta: PropTypes.string.isRequired,
  }).isRequired,
  updateValue: PropTypes.func.isRequired,
};

class PageComp extends Component {
  constructor(...args) {
    super(...args);

    this.state = {
      activeBlockId: null,
    };

    this.fetchBlocks = this.fetchBlocks.bind(this);
    this.fetchFlags = this.fetchFlags.bind(this);
    this.updateValue = this.updateValue.bind(this);
    this.updateBlock = this.updateBlock.bind(this);
    this.moveBlock = this.moveBlock.bind(this);
    this.liveUpdateBlock = this.liveUpdateBlock.bind(this);
    this.savePage = this.savePage.bind(this);
    this.createNewBlock = this.createNewBlock.bind(this);
    this.deleteBlock = this.deleteBlock.bind(this);
    this.openPropertyPanel = this.openPropertyPanel.bind(this);
    this.isBlockEnabled = this.isBlockEnabled.bind(this);
  }

  componentDidMount() {
    this.fetchBlocks();
    this.fetchFlags();
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ page: undefined });
    this.fetchBlocks(nextProps);
  }

  openPropertyPanel(item) {
    this.setState({
      activeBlockId: item.id,
    });
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  closePropertyPanel() {
    this.setState({
      activeBlockId: null,
    });
  }

  fetchBlocks(props = this.props) {
    API.fetch(`pages/${props.match.params.id}`).then((page) => {
      this.setState({
        page: {
          ...page,
          page_blocks: page.page_blocks.map(parseBlock),
        },
        showPageSettings: !page.page_blocks.length,
      });
    });
  }

  fetchFlags() {
    API.fetch('flags').then((flags) => {
      this.setState({
        flags,
      });
    });
  }

  updateValue(e, prop) {
    const { page } = this.state;

    switch ((e && e.target && e.target.id) || '') {
      case 'pagetitle':
        this.setState({
          page: {
            ...page,
            title: e.target.value,
          },
        });
        break;

      case 'pageslug':
        this.setState({
          page: {
            ...page,
            slug: e.target.value,
          },
        });
        break;

      default:
        if (!prop) {
          throw new Error('No prop');
        }
        this.setState({
          page: {
            ...page,
            [prop]: e,
          },
        });
        break;
    }
  }

  updateBlock(item, func = null) {
    let { page } = this.state;

    if (func && typeof func === 'function') {
      this.setState((state) => {
        page = state.page;

        const block = page.page_blocks.find((i) => i.id === item.id);
        const patchedBlock = func(block);

        return ({
          page: {
            ...page,
            page_blocks: page.page_blocks.map((i) => (i.id === patchedBlock.id ? patchedBlock : i)),
          },
        });
      });
      return;
    }

    this.setState({
      page: {
        ...page,
        page_blocks: page.page_blocks.map((i) => (i.id === item.id ? item : i)),
      },
    });
  }

  moveBlock(item, positionChange) {
    const { page } = this.state;
    const blocks = extend(true, [], page.page_blocks);
    const curBlock = blocks.find((i) => (i.id === item.id));
    const curPosition = blocks.indexOf(curBlock);
    const afterMovePosition = curPosition + positionChange;

    if (curPosition !== -1 && afterMovePosition >= 0 && afterMovePosition <= blocks.length) {
      const block = blocks.splice(curPosition, 1)[0];
      blocks.splice(afterMovePosition, 0, block);
      blocks.forEach((i, idx) => {
        blocks[idx].sortorder = blocks.indexOf(i);
      });
      this.setState({
        page: {
          ...page,
          page_blocks: blocks,
        },
      });
    }
  }

  liveUpdateBlock(diff) {
    const { page, activeBlockId } = this.state;
    const block = page.page_blocks.filter((b) => b.id === activeBlockId)[0];
    const ix = page.page_blocks.indexOf(block);

    if (block) {
      const blocks = [...page.page_blocks];
      blocks[ix] = extend(true, {}, block, diff);
      this.setState({
        page: {
          ...page,
          page_blocks: blocks,
        },
      });
    }
  }

  savePage(e) {
    const { page } = this.state;
    const { match } = this.props;
    const stringifiedPage = {
      ...page,
      page_blocks: page.page_blocks.map((i) => {
        if (typeof i.content === 'object') {
          return {
            ...i,
            content: JSON.stringify(i.content),
          };
        }
        return i;
      }),
    };

    API.fetch(`pages/${match.params.id}`, 'PUT', stringifiedPage).then(() => {
      this.fetchBlocks();
    });
    this.setState({ page: undefined });
    e.preventDefault();
  }

  createNewBlock(type) {
    const PropertiesBlock = resolvePropertiesBlock(type);
    const hasDefaultContent = PropertiesBlock && 'defaultContent' in PropertiesBlock;
    const content = hasDefaultContent ? PropertiesBlock.defaultContent() : '';
    const { match } = this.props;
    const { page } = this.state;
    const body = {
      pages_id: match.params.id,
      type,
      size: BlockSize.Full,
      content: JSON.stringify(content),
    };
    API.fetch('pageblocks', 'POST', body).then((newBlock) => {
      const block = parseBlock(newBlock);

      this.setState({
        showPageSettings: false,
        page: {
          ...page,
          page_blocks: [...page.page_blocks, block],
        },
      });
    });
  }

  deleteBlock(id) {
    const { page } = this.state;
    API.fetch(`pageblocks/${id}`, 'DELETE', {}).then(() => {
      // asdf
    });
    this.setState({
      page: {
        ...page,
        page_blocks: page.page_blocks.filter((block) => id !== block.id),
      },
    });
  }

  isBlockEnabled(type) {
    const { flags } = this.state;
    return this.state && flags && flags.blocks && flags.blocks[type];
  }

  render() {
    const {
      page, activeBlockId, showPageSettings,
    } = this.state;

    if (!page) {
      return (
        <div className="cl-mcont">
          <Spinner />
        </div>
      );
    }

    let propertyPanel = null;
    let propertyPanelClass = 'hide';
    let block;
    const activeBlock = page.page_blocks.filter((pblock) => pblock.id === activeBlockId)[0];

    if (activeBlock) {
      block = resolveBlock(activeBlock.type);
      const PropertiesBlock = block.properties;
      if (PropertiesBlock) {
        // eslint-disable-next-line no-unused-vars
        propertyPanelClass = 'show';
        propertyPanel = (
          <PropertiesBlock
            content={activeBlock.content}
            size={activeBlock.size}
            placing={activeBlock.placing}
            classes={activeBlock.classes}
            onChange={this.liveUpdateBlock}
          />
        );
      }
    }

    const addBlockButtons = getBlockTypes().map(
      (blockType) => this.isBlockEnabled(blockType.type) && (
        <button key={blockType.type} className="btn btn-default btn-lg" onClick={() => this.createNewBlock(blockType.type)} type="button">
          <i className={`fa ${blockType.icon}`} />
          {' '}
          {blockType.title}
        </button>
      ),
    );

    return (
      <div>
        <div id="page-header" className="navbar navbar-default">
          <h2>
            Sida:&nbsp;
            {page.title}
          </h2>
          <div className="pull-right">
            <Link to={`/website/${page.websites_id}/`} className="btn btn-default">
              <i className="fa fa-arrow-left" />
              {' '}
              Tillbaka
            </Link>
            &nbsp;
            <button className={`btn btn-default ${showPageSettings ? 'active' : ''}`} onClick={() => this.setState({ showPageSettings: !showPageSettings })} type="button">
              <i className="fa fa-wrench" />
              {' '}
              Sidinställningar
            </button>
            &nbsp;
            <button className="btn btn-primary" href="#" onClick={this.savePage} type="button">
              <i className="fa fa-cloud-upload" />
              {' '}
              Spara ändringar
            </button>
          </div>
        </div>
        <div className="cl-mcont">
          <div className="row">
            { showPageSettings
              ? (
                <>
                  <PageSettings page={page} updateValue={this.updateValue} />
                  <div className="col-md-12">
                    <h2>Lägg till ett block</h2>
                    {addBlockButtons}
                  </div>
                </>
              )
              : (
                <>
                  <ul>
                    {page.page_blocks.map((value, index) => (
                      <div
                        role="button"
                        tabIndex="0"
                        onClick={() => {
                          this.openPropertyPanel(value); // value === item
                        }}
                        onKeyPress={(v) => v}
                        key={value.id}
                      >
                        <Block
                          key={value.id}
                          index={index}
                          value={value}
                          updateBlock={this.updateBlock}
                          deleteBlock={this.deleteBlock}
                          moveBlock={this.moveBlock}
                          isSorting={false}
                        />
                      </div>
                    ))}
                  </ul>

                  <div className="col-md-12">
                    <h2>Lägg till ett block</h2>
                    {addBlockButtons}
                  </div>
                </>
              )}
          </div>

          <PropertyPanel>
            {propertyPanelClass !== 'hide' ? (
              <div className={`property-panel ${propertyPanelClass}`}>
                <div className="property-panel__header">
                  <div className="property-panel__header__title">
                    <h4>{block ? block.title : ''}</h4>
                  </div>
                </div>
                {propertyPanel}
              </div>
            ) : null}
          </PropertyPanel>
        </div>
      </div>
    );
  }
}

PageComp.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
    }),
  }).isRequired,
};

// why do work now when I can do work later
function Page() {
  const params = useParams();
  return <PageComp match={{ params }} />;
}

export default Page;
