import React from 'react';
import PropTypes from 'prop-types';

function Instagram({ item }) {
  if (typeof item === 'undefined') {
    return <div />;
  }

  return (
    <strong>
      Instagraminlägg för
      {' "'}
      {item.content.username}
      {'" '}
      kommer att visas här.
    </strong>
  );
}

Instagram.propTypes = {
  item: PropTypes.object.isRequired,
};

export default Instagram;
