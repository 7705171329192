import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './sidebar.css';

import ccc from '../core/classes';
import API from '../core/api';
import { PropertyPanelContainer } from './propertypanel';

function Sidebar() {
  const [navOpen, setNavOpen] = useState(false);
  const [websites, setWebsites] = useState(null);
  const [menuOpen, setMenuOpen] = useState('');
  const [search, setSearch] = useState('');
  const [tab, setTab] = useState('pages');

  const toggleMenu = (menu) => {
    setMenuOpen(menuOpen === menu ? '' : menu);
  };

  useEffect(() => {
    API.fetch('websites').then((res) => setWebsites(res));
  }, []);

  return (
    <div className="cl-sidebar">
      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
      <a href="#" className="cl-toggle" onClick={() => setNavOpen(!navOpen)}><i className="fa fa-bars" /></a>
      <div className="cl-navblock">

        <div className="menu-space">
          <div className="content">
            <div className="sidebar-logo">
              <img src={`${process.env.PUBLIC_URL}/w46_big_logo_payoff_white.png`} alt="Webb46" className="img-responsive" style={{ maxHeight: '100%' }} />
            </div>
            <div className="sidebarcontent" style={{ visibility: tab === 'pages' ? 'visible' : 'hidden' }}>
              <ul className="cl-vnavigation" style={{ display: navOpen ? 'block' : 'none' }}>
                <li>
                  <Link to="/">
                    <i className="fa fa-home" />
                    <span>Hem</span>
                  </Link>
                </li>

                {websites && websites.map((item) => (
                  <li
                    className={ccc({
                      parent: true,
                      open: menuOpen === `website-${item.id}`,
                      'search-hidden': item.name.toLowerCase().indexOf(search.toLowerCase()) === -1,
                    })}
                    key={`website-${item.id}`}
                  >
                    <a href="#" onClick={(e) => { e.preventDefault(); toggleMenu(`website-${item.id}`); }}>
                      <i className="fa fa-globe" />
                      <span>{item.name}</span>
                    </a>
                    <ul className="sub-menu" style={{ display: menuOpen === `website-${item.id}` ? 'block' : 'none' }}>
                      <li>
                        <Link to={`/website/${item.id}/`}>
                          <i className="fa fa-globe" />
                          {' '}
                          Översikt
                        </Link>
                      </li>
                      <li>
                        <Link to={`/website/${item.id}/settings`}>
                          <i className="fa fa-wrench" />
                          {' '}
                          Inställningar
                        </Link>
                      </li>
                      { item.pages && item.pages.map((subitem) => (
                        <li key={`page-${subitem.id}`}>
                          <Link to={`/page/${subitem.id}/`}>
                            <i className="fa fa-file" />
                            {' '}
                            {subitem.title}
                          </Link>
                        </li>
                      )) }
                    </ul>
                  </li>
                )) }

                <li>
                  <Link to="/logout">
                    <i className="fa fa-key" />
                    <span>Logga ut</span>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="sidebarcontent" style={{ visibility: tab === 'properties' ? 'visible' : 'hidden' }}>
              <PropertyPanelContainer />
            </div>
          </div>
        </div>
        <div className="text-right collapse-button" style={{ padding: '7px 9px' }}>
          <input type="text" className="form-control search" style={{ width: '100%', margin: 0 }} onChange={(e) => setSearch(e.target.value)} placeholder="Sök..." />
          <div className="text-left" style={{ margin: '10px -7px -7px -7px' }}>
            <button type="button" className="btn btn-default" style={{ color: 'rgba(255, 255, 255, 0.6)', width: '50%' }} onClick={() => setTab('pages')}>
              <span style={tab === 'pages' ? {
                borderBottom: '1px solid white', padding: '0 5px 5px 5px', fontStyle: 'bold', color: 'rgba(255, 255, 255, 0.9)',
              } : null}
              >
                <i className="fa fa-globe" style={{ color: 'inherit' }} />
                {' '}
                Hemsidor
              </span>
            </button>
            <button type="button" id="sidebar-properties-button" className={`btn btn-default ${tab === 'properties' ? 'btn-active' : ''}`} style={{ color: 'rgba(255, 255, 255, 0.6)', width: '50%' }} onClick={() => setTab('properties')}>
              <span style={tab === 'properties' ? {
                borderBottom: '1px solid white', padding: '0 5px 5px 5px', fontStyle: 'bold', color: 'rgba(255, 255, 255, 0.9)',
              } : null}
              >
                <i className="fa fa-paint-brush" style={{ color: 'inherit' }} />
                {' '}
                Egenskaper
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
