import React from 'react';
import PropTypes from 'prop-types';
import { BlockSizeDropdown } from '../../components/BlockSizeDropdown';
import { BlockPlacingDropdown } from '../../components/BlockPlacingDropdown';
import Dropdown from '../../../components/dropdown/Dropdown';
import BlockClassesField from '../../components/BlockClassesField';

export const GalleryTypes = Object.freeze({
  Gallery: 0,
  Slideshow: 1,
  List: 2,
  Tiles: 3,
});

export const galleryTypeOptions = [
  { name: 'Galleri', value: GalleryTypes.Gallery },
  { name: 'Bildspel', value: GalleryTypes.Slideshow },
  { name: 'Lista', value: GalleryTypes.List },
  { name: 'Kakel', value: GalleryTypes.Tiles },
];

function ImageProperties({
  onChange, size, placing, content, classes,
}) {
  const handleBlockSizeChange = (newSize) => {
    const diff = {
      size: newSize,
    };
    onChange(diff);
  };

  const handleBlockPlacingChange = (newPlacing) => {
    const diff = {
      placing: newPlacing,
    };
    onChange(diff);
  };

  const handleGalleryTypeChange = (v) => {
    const diff = {
      content: {
        galleryType: v,
      },
    };
    onChange(diff);
  };

  return (
    <div>
      <BlockSizeDropdown defaultValue={size} onChange={handleBlockSizeChange} />
      <BlockPlacingDropdown defaultValue={placing} onChange={handleBlockPlacingChange} />
      <BlockClassesField defaultValue={classes} onChange={onChange} />

      <Dropdown
        name="Visa bilder som"
        id="gallery-type"
        options={galleryTypeOptions}
        defaultValue={content.galleryType || '0'}
        onChange={handleGalleryTypeChange}
      />
    </div>
  );
}

ImageProperties.propTypes = {
  onChange: PropTypes.func.isRequired,
  size: PropTypes.number.isRequired,
  placing: PropTypes.number.isRequired,
  classes: PropTypes.string.isRequired,
  // eslint-disable-next-line
  content: PropTypes.object,
};

export default ImageProperties;
