import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Slider from 'rc-slider';
import { BlockSizeDropdown } from '../../components/BlockSizeDropdown';
import { BlockPlacingDropdown } from '../../components/BlockPlacingDropdown';
import BlockClassesField from '../../components/BlockClassesField';

export default class YoutubeProperties extends Component {
  static defaultContent() {
    return {
      style: {
        height: '315',
        width: '40',
        align: 'center',
      },
      url: '',
    };
  }

  constructor(props) {
    super(props);

    this.handleHeightChange = this.handleHeightChange.bind(this);
    this.handleWidthChange = this.handleWidthChange.bind(this);
    this.handleAlignChange = this.handleAlignChange.bind(this);
    this.handleurlChange = this.handleurlChange.bind(this);
    this.handleBlockSizeChange = this.handleBlockSizeChange.bind(this);
  }

  handleHeightChange(height) {
    const diff = {
      content: {
        style: {
          height,
        },
      },
    };
    const { onChange } = this.props;
    onChange(diff);
  }

  handleWidthChange(width) {
    const diff = {
      content: {
        style: {
          width,
        },
      },
    };
    const { onChange } = this.props;
    onChange(diff);
  }

  handleAlignChange(event) {
    const diff = {
      content: {
        style: {
          align: event.target.value,
        },
      },
    };
    const { onChange } = this.props;
    onChange(diff);
  }

  handleurlChange(event) {
    const regex = /((www\.)?youtube\.com\/watch\?v=|youtu\.be\/)/miug;
    let u = event.target.value;
    const replacement = 'youtube.com/embed/';
    u = u.replace(regex, replacement);
    const diff = {
      content: {
        url: u,
      },
    };
    const { onChange } = this.props;
    onChange(diff);
  }

  handleBlockSizeChange(size) {
    const diff = {
      size,
    };
    const { onChange } = this.props;
    onChange(diff);
  }

  handleBlockPlacingChange(placing) {
    const diff = {
      placing,
    };
    const { onChange } = this.props;
    onChange(diff);
  }

  render() {
    const {
      content, size, placing, classes, onChange,
    } = this.props;

    return (
      <div>
        <BlockSizeDropdown defaultValue={size} onChange={this.handleBlockSizeChange} />
        <BlockPlacingDropdown defaultValue={placing} onChange={this.handleBlockPlacingChange} />
        <BlockClassesField defaultValue={classes} onChange={onChange} />

        <div className="field">
          <label htmlFor="prop-url">Länk till youtube-klipp</label>
          <input
            id="prop-url"
            type="text"
            placeholder="www.youtube.com/er345"
            value={content.url}
            onChange={this.handleurlChange}
          />
        </div>

        <div className="field">
          <label htmlFor="prop-align">Justering</label>
          <select
            id="prop-align"
            value={content.style.align}
            onChange={this.handleAlignChange}
          >
            <option value="left">Vänster</option>
            <option value="center">Centrerad</option>
            <option value="right">Höger</option>
          </select>
        </div>

        <div className="field">
          <label htmlFor="prop-height">Höjd på video</label>
          <Slider
            id="prop-height"
            min={1}
            max={1000}
            defaultValue={content.style.height}
            step={1}
            onChange={this.handleHeightChange}
          />
        </div>

        <div className="field">
          <label htmlFor="prop-width">Bredd på video</label>
          <Slider
            id="prop-width"
            min={1}
            max={100}
            defaultValue={content.style.width}
            step={1}
            onChange={this.handleWidthChange}
          />
        </div>
      </div>
    );
  }
}

YoutubeProperties.propTypes = {
  onChange: PropTypes.func.isRequired,
  size: PropTypes.number.isRequired,
  placing: PropTypes.number.isRequired,
  classes: PropTypes.string.isRequired,
  content: PropTypes.shape({
    style: PropTypes.shape({
      align: PropTypes.func.isRequired,
      height: PropTypes.number.isRequired,
      width: PropTypes.number.isRequired,
    }).isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
};
