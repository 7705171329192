import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ChromePicker } from 'react-color';
import Slider from 'rc-slider';
import { BlockSizeDropdown } from '../../components/BlockSizeDropdown';
import { BlockPlacingDropdown } from '../../components/BlockPlacingDropdown';
import BlockClassesField from '../../components/BlockClassesField';

export default class AnchorProperties extends Component {
  static defaultContent() {
    return {
      style: {
        borderWidth: 1,
        borderColor: {
          r: 30,
          g: 158,
          b: 206,
          a: 1,
        },
        width: '100',
        align: 'center',
      },
    };
  }

  constructor(props) {
    super(props);

    this.handleColorChange = this.handleColorChange.bind(this);
    this.handleThicknessChange = this.handleThicknessChange.bind(this);
    this.handleWidthChange = this.handleWidthChange.bind(this);
    this.handleAlignChange = this.handleAlignChange.bind(this);
    this.handleBlockSizeChange = this.handleBlockSizeChange.bind(this);
    this.handleBlockPlacingChange = this.handleBlockPlacingChange.bind(this);
  }

  handleColorChange(color) {
    const diff = {
      content: {
        style: {
          borderColor: color.rgb,
        },
      },
    };
    const { onChange } = this.props;
    onChange(diff);
  }

  handleThicknessChange(borderWidth) {
    const diff = {
      content: {
        style: {
          borderWidth,
        },
      },
    };
    const { onChange } = this.props;
    onChange(diff);
  }

  handleAlignChange(event) {
    const diff = {
      content: {
        style: {
          align: event.target.value,
        },
      },
    };
    const { onChange } = this.props;
    onChange(diff);
  }

  handleWidthChange(width) {
    const diff = {
      content: {
        style: {
          width,
        },
      },
    };
    const { onChange } = this.props;
    onChange(diff);
  }

  handleBlockSizeChange(size) {
    const diff = {
      size,
    };
    const { onChange } = this.props;
    onChange(diff);
  }

  handleBlockPlacingChange(placing) {
    const diff = {
      placing,
    };
    const { onChange } = this.props;
    onChange(diff);
  }

  render() {
    const {
      size, placing, content, classes, onChange,
    } = this.props;

    return (
      <div>
        <BlockSizeDropdown defaultValue={size} onChange={this.handleBlockSizeChange} />
        <BlockPlacingDropdown defaultValue={placing} onChange={this.handleBlockPlacingChange} />
        <BlockClassesField defaultValue={classes} onChange={onChange} />

        <div className="field">
          <label htmlFor="content-text">Färg</label>
          <ChromePicker
            color={content.style.borderColor}
            onChangeComplete={this.handleColorChange}
          />
        </div>

        <div className="field">
          <label htmlFor="content-text">Tjocklek</label>
          <Slider
            min={1}
            max={10}
            defaultValue={content.style.borderWidth}
            step={1}
            onChange={this.handleThicknessChange}
          />
        </div>

        <div className="field">
          <label htmlFor="content-text">Bredd</label>
          <Slider
            min={1}
            max={100}
            defaultValue={content.style.width}
            step={1}
            onChange={this.handleWidthChange}
          />
        </div>

        <div className="field">
          <label htmlFor="content-text">Justering</label>
          <select
            value={content.style.align}
            onChange={this.handleAlignChange}
            className="form-control"
          >
            <option value="left">Vänster</option>
            <option value="center">Centrerad</option>
            <option value="right">Höger</option>
          </select>
        </div>
      </div>
    );
  }
}

AnchorProperties.propTypes = {
  onChange: PropTypes.func.isRequired,
  size: PropTypes.number.isRequired,
  placing: PropTypes.number.isRequired,
  classes: PropTypes.string.isRequired,
  content: PropTypes.shape({
    style: PropTypes.shape({
      borderWidth: PropTypes.number.isRequired,
      align: PropTypes.number.isRequired,
      width: PropTypes.number.isRequired,
      borderColor: PropTypes.number.isRequired,
      color: PropTypes.shape({
        r: PropTypes.number.isRequired,
        g: PropTypes.number.isRequired,
        b: PropTypes.number.isRequired,
        a: PropTypes.number.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};
