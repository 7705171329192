import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from '../../components/dropdown/Dropdown';

export const BlockPlacing = Object.freeze({
  Main: '0',
  Sidebar: '1',
});

export const blockPlacingOpts = [
  { name: 'Huvudinnehåll', value: BlockPlacing.Main },
  { name: 'Sidopanel', value: BlockPlacing.Sidebar },
];

export function BlockPlacingDropdown({ defaultValue = BlockPlacing.Main, onChange }) {
  return (
    <Dropdown
      name="Blockplacering"
      id="prop-block-placing"
      options={blockPlacingOpts}
      defaultValue={defaultValue}
      onChange={onChange}
    />
  );
}

BlockPlacingDropdown.propTypes = {
  defaultValue: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
