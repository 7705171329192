import React from 'react';
import PropTypes from 'prop-types';

import API from '../../../core/api';
import Spinner from '../../../components/spinner';

import './mediamanager.css';

export default class MediaManager extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };

    this.fileInput = React.createRef();

    this.onUpload = this.onUpload.bind(this);
    this.removeItem = this.removeItem.bind(this);
  }

  onUpload(e) {
    const files = Array.from(e.target.files);
    const allPromises = [];

    this.setState({ loading: true });

    files.forEach((f) => {
      const formData = new FormData();

      formData.set('file', f);
      const { item, onChange } = this.props;
      allPromises.push(API.fetch(`pageblocks/${item.id}/attach`, 'POST', formData).then((result) => {
        if (!result.message) { // This is an error
          onChange(item, (patchitem) => ({
            ...patchitem,
            mediaitems: [
              ...patchitem.mediaitems,
              result,
            ],
          }));
        }
      }));
    });

    Promise.all(allPromises).then(() => {
      this.setState({ loading: false });
    }).catch(() => {
      this.setState({ loading: false });
    });
  }

  removeItem(mediaid) {
    this.setState({ loading: true });
    const { item, onChange } = this.props;
    API.fetch(`pageblocks/${item.id}/remove/${mediaid}`, 'DELETE', {}).then((result) => {
      if (result.status === 200) {
        onChange({
          ...item,
          mediaitems: item.mediaitems.filter((media) => mediaid !== media.id),
        });
      }
      this.setState({ loading: false, removeId: undefined });
    });
  }

  render() {
    const { item } = this.props;
    const { loading, removeId, detailsId } = this.state;

    if (typeof item === 'undefined') {
      return <div />;
    }

    if (removeId) {
      return (
        <div className="mediamanager">
          <h4>Ta bort bild</h4>
          Är du säker på att du vill ta bort denna bild?
          <br />
          <button type="button" className="btn btn-danger" href="#" onClick={() => this.removeItem(removeId)}>Ta bort</button>
          <button type="button" className="btn btn-default" href="#" onClick={() => this.setState({ detailsId: undefined, removeId: undefined })}>Avbryt</button>
        </div>
      );
    }

    if (detailsId) {
      const currentMedia = item.mediaitems.find((finditem) => finditem.id === detailsId);

      return (
        <div className="mediamanager">
          <h4>Detaljer för denna bild</h4>
          <div className="mediaitem large" style={{ display: 'inline-block' }}>
            <img src={currentMedia.fullurl} alt={currentMedia.name} />
          </div>
          &nbsp;
          <div style={{ display: 'inline-block' }}>
            <button type="button" className="btn btn-danger" href="#" onClick={() => this.setState({ detailsId: undefined, removeId: detailsId })}>Ta bort denna bild</button>
            <button type="button" className="btn btn-default" href="#" onClick={() => this.setState({ detailsId: undefined })}>Återgå</button>
            <br />
            <h4>URL till full storlek</h4>
            <input type="text" value={currentMedia.fullurl} className="form-control" style={{ width: '100%' }} onChange={() => {}} />
          </div>
        </div>
      );
    }

    return (
      <div className="mediamanager">
        {item.mediaitems.map((media) => (
          <div key={media.id} type="button" aria-hidden="true" className="mediaitem" href="#" onClick={() => this.setState({ detailsId: media.id })}>
            <img src={media.url} alt={media.name} />
            <button
              type="button"
              className="btn btn-sm btn-danger remove"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                this.setState({ detailsId: undefined, removeId: media.id });
              }}
            >
              <i className="fa fa-times" />
            </button>
          </div>
        ))}
        {loading ? (
          (
            <button type="button" className="mediaitem btn">
              <Spinner padding="5px" />
            </button>
          )
        ) : (
          <button type="button" className="mediaitem btn" onClick={() => this.fileInput.current.click()}>
            <i className="fa fa-upload" />
            <br />
            Ladda upp
          </button>
        )}

        <input ref={this.fileInput} className="fileinput" type="file" id="fileuploader" onChange={this.onUpload} multiple />
      </div>
    );
  }
}

MediaManager.propTypes = {
  item: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};
