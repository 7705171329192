function API() {
  this.state = {};

  this.fetch = function apiFetch(url, method = 'GET', body = {}) {
    let processedBody = body.toString() === '[object Object]' ? JSON.stringify(body) : body;
    let contentType = 'application/json';

    if (typeof processedBody !== 'string') {
      contentType = null;
    }

    if (method === 'GET') {
      processedBody = null;
    }

    const opts = {
      method,
      body: processedBody,
      headers: {
        Accept: 'application/json',
        Authorization: this.state && `${this.state.token_type} ${this.state.access_token}`,
      },
    };

    if (contentType) {
      opts.headers['Content-Type'] = contentType;
    }

    return fetch(`/api/${url}`, opts).then((res) => res.json())
      .then((res) => (res.error ? Promise.reject(res) : Promise.resolve(res)));
  };

  this.login = function apiLogin(body = {}) {
    return this.fetch('auth/login', 'POST', body).then((result) => {
      if (!result.error) {
        this.state = result;
      }
      return result;
    });
  };

  this.logout = function apiLogout() {
    this.state = {};
  };

  this.set = function apiSet(state = {}) {
    this.state = state;
  };

  return this;
}

export default (new API());
