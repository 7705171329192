import React, { Component } from 'react';
import PropTypes from 'prop-types';
import extend from 'extend';
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
  arrayMove,
} from 'react-sortable-hoc';

import Spinner from '../components/spinner';
import util from '../core/util';

const TYPE_PAGE = 'page';
const TYPE_CUSTOM = 'custom';
const TYPE_NEWS = 'news';

const PAGE_TYPE_ORDINARY = 0;
const PAGE_TYPE_NEWS = 1;

function ListItemConfirmDelete(props) {
  const { onConfirm, onCancel } = props;
  return (
    <li className="list-item">
      <div className="flex-grow titles">
        Är du säker?
      </div>
      <div className="flex-fixed">
        <button type="button" className="btn btn-danger" onClick={onConfirm}>Ta bort</button>
        <button type="button" className="btn btn-default" onClick={onCancel}>Avbryt</button>
      </div>
    </li>
  );
}

ListItemConfirmDelete.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

function ListItemIcon(props) {
  const { onClick, iconName } = props;
  return (
    <button
      type="button"
      className="btn btn-default icon"
      onClick={onClick}
    >
      <i className={`fa ${iconName}`} aria-hidden="true" />
    </button>
  );
}

ListItemIcon.propTypes = {
  iconName: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

const DragHandle = sortableHandle(() => (
  <div className="flex-fixed btn icon move">
    <i className="fa fa-ellipsis-v" aria-hidden="true" />
  </div>
));

const ListItem = sortableElement((props) => (
  <li className={`list-item ${props.active ? 'active' : ''}`}>
    <DragHandle />
    <div className="flex-grow titles">
      <div className="title">{props.title || '\u00a0'}</div>
      <div className="sub-title">{props.subTitle || '\u00a0'}</div>
    </div>
    <div className="flex-fixed">
      <ListItemIcon iconName="fa-pencil" onClick={() => props.onEdit(props.id)} />
      <ListItemIcon iconName="fa-trash-o" onClick={() => props.onDelete(props.id)} />
    </div>
  </li>
));

ListItem.propTypes = {
  id: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  active: PropTypes.bool,
};

ListItem.defaultProps = {
  title: '',
  subTitle: '',
  active: false,
};

const SortableContainer = sortableContainer(({ children }) => (<ul>{children}</ul>));

function Settings({
  item, pages, onNameChange, onTypeChange, onPageChange, onUrlChange,
}) {
  return (
    <div className="block-flat navigation-editor">
      <div className="header">
        <h3>Inställningar</h3>
      </div>
      <div className="content">
        <div className="field">
          <label htmlFor="item-name">Namn i menyn</label>
          <input
            id="item-name"
            type="text"
            className="form-control"
          // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            value={item.name}
            onChange={onNameChange}
          />
        </div>

        <div className="field">
          <label htmlFor="item-type">Länk typ</label>
          <select
            id="item-type"
            className="form-control"
            onChange={onTypeChange}
            value={item.type}
          >
            <option value={TYPE_PAGE}>Sida</option>
            <option value={TYPE_NEWS}>Nyhet</option>
            <option value={TYPE_CUSTOM}>Anpassad</option>
          </select>
        </div>

        {item.type === TYPE_PAGE
        && (
        <div className="field">
          <label htmlFor="item-page">Målsida</label>
          <select
            id="item-page"
            className="form-control"
            onChange={onPageChange}
            value={item.pageId}
          >
            <option value="">Välj sida</option>
            {
              pages.filter(({ type }) => type === PAGE_TYPE_ORDINARY).map((page) => (
                <option key={page.id} value={page.id}>{page.title}</option>
              ))
            }
          </select>
        </div>
        )}

        {item.type === TYPE_NEWS
        && (
        <div className="field">
          <label htmlFor="item-page">Målsida</label>
          <select
            id="item-page"
            className="form-control"
            onChange={onPageChange}
            value={item.pageId}
          >
            <option value="">Välj nyhet</option>
            {
              pages.filter(({ type }) => type === PAGE_TYPE_NEWS).map((page) => (
                <option key={page.id} value={page.id}>{page.title}</option>
              ))
            }
          </select>
        </div>
        )}

        {item.type === TYPE_CUSTOM
        && (
        <div className="field">
          <label htmlFor="item-url">Adress</label>
          <input
            id="item-url"
            type="text"
            className="form-control"
            value={item.url || 'http://'}
            onChange={onUrlChange}
          />
        </div>
        )}
      </div>
    </div>
  );
}

Settings.propTypes = {
  onNameChange: PropTypes.func.isRequired,
  onTypeChange: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onUrlChange: PropTypes.func.isRequired,
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    pageId: PropTypes.string.isRequired,
  }).isRequired,
  pages: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
  })).isRequired,
};

class NavigationEditor extends Component {
  constructor(...args) {
    super(...args);

    this.state = {
      items: [],
      activeId: undefined,
    };

    this.onChange = this.onChange.bind(this);
    this.addOption = this.addOption.bind(this);
    this.getItemById = this.getItemById.bind(this);
    this.onNameChange = this.onNameChange.bind(this);
    this.onTypeChange = this.onTypeChange.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.onUrlChange = this.onUrlChange.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onDeleteConfirm = this.onDeleteConfirm.bind(this);
    this.onDeleteCancel = this.onDeleteCancel.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.onSortEnd = this.onSortEnd.bind(this);
    this.updateItem = this.updateItem.bind(this);
    this.getItemSubTitle = this.getItemSubTitle.bind(this);
  }

  componentDidMount() {
    let items;
    const { website } = this.props;

    try {
      items = JSON.parse(website.nav) || [];
    } catch (e) {
      items = [];
    }

    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({ items });
  }

  onNameChange(event) {
    const { value } = event.target;
    this.updateItem('name', value);
  }

  onTypeChange(event) {
    const { value } = event.target;
    this.updateItem('type', value);
  }

  onPageChange(event) {
    const { value } = event.target;
    this.updateItem('pageId', value);
  }

  onUrlChange(event) {
    const { value } = event.target;
    this.updateItem('url', value);
  }

  onEdit(itemId) {
    this.setState({
      activeId: itemId,
    });
  }

  onDelete(itemId) {
    this.setState({
      confirmDelete: itemId,
    });
  }

  onDeleteConfirm() {
    const { confirmDelete: itemId, items } = this.state;
    this.setState({
      items: items.filter((item) => item.id !== itemId),
    }, this.onChange);
  }

  onDeleteCancel() {
    this.setState({
      confirmDelete: undefined,
    });
  }

  onSortEnd({ oldIndex, newIndex }) {
    this.setState(({ items }) => ({
      items: arrayMove(items, oldIndex, newIndex),
    }), this.onChange);
  }

  onChange() {
    const { items } = this.state;
    const { website, onChange } = this.props;

    onChange({
      website: {
        ...website,
        nav: JSON.stringify(items),
      },
    });
  }

  getpageById(pageId) {
    const { website } = this.props;
    const { pages } = website;
    return pages.find((page) => `${page.id}` === pageId);
  }

  getItemById(id) {
    const { items } = this.state;
    return items.find((item) => item.id === id);
  }

  getItemSubTitle(item) {
    switch (item.type) {
      case TYPE_PAGE:
        return `Sida: ${(this.getpageById(item.pageId) || {}).title || ''}`;
      case TYPE_NEWS:
        return `Nyhet: ${(this.getpageById(item.pageId) || {}).title || ''}`;
      default:
        return item.url;
    }
  }

  updateItem(propName, value) {
    const { activeId, items } = this.state;
    const item = this.getItemById(activeId);
    const ix = items.indexOf(item);

    if (item) {
      const newItems = [...items];
      newItems[ix] = extend(true, {}, item, { [propName]: value });
      this.setState({
        items: newItems,
      }, this.onChange);
    }
  }

  addOption() {
    this.setState((state) => {
      const id = util.generateId();
      const items = state.items.concat({
        id,
        type: TYPE_PAGE,
        name: '',
        url: '',
        pageId: '',
      });

      return {
        items,
        activeId: id,
      };
    }, this.onChange);
  }

  render() {
    const { website } = this.props;
    const {
      activeId,
      items,
      confirmDelete,
    } = this.state;

    if (!website) {
      return (
        <div className="cl-mcont">
          <Spinner />
        </div>
      );
    }

    const activeItem = this.getItemById(activeId);
    const { pages } = website;
    // const pages = website.pages.filter((page) => page.type === PAGE_TYPE_ORDINARY);

    return (
      <div className="row flexwraps">
        <div className="col-sm-6 col-md-6 navigation-editor">
          <div className="block-flat">
            <div className="header">
              <h3>Menyalternativ</h3>
            </div>
            <div className="content">
              <SortableContainer onSortEnd={this.onSortEnd} useDragHandle>
                {
                  items.map((item, index) => (
                    confirmDelete === item.id
                      ? (
                        <ListItemConfirmDelete
                          key={item.id}
                          onConfirm={this.onDeleteConfirm}
                          onCancel={this.onDeleteCancel}
                        />
                      )
                      : (
                        <ListItem
                          id={item.id}
                          key={item.id}
                          index={index}
                          active={item.id === activeId}
                          title={item.name}
                          subTitle={this.getItemSubTitle(item)}
                          onEdit={this.onEdit}
                          onDelete={this.onDelete}
                        />
                      )
                  ))
                }
                <button type="button" onClick={this.addOption} className="btn btn-default list-item add-button">
                  + Lägg till alternativ
                </button>
              </SortableContainer>
            </div>
          </div>
        </div>

        <div className="col-sm-6 col-md-6">
          {activeItem
            && (
            <Settings
              key={activeItem.id}
              item={activeItem}
              pages={pages}
              onNameChange={this.onNameChange}
              onTypeChange={this.onTypeChange}
              onPageChange={this.onPageChange}
              onUrlChange={this.onUrlChange}
            />
            )}
        </div>
      </div>
    );
  }
}

NavigationEditor.propTypes = {
  website: PropTypes.shape({
    id: PropTypes.number.isRequired,
    nav: PropTypes.string.isRequired,
    pages: PropTypes.array.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default NavigationEditor;
