/* eslint-disable no-constant-condition */
import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import Spinner from '../components/spinner';

import API from '../core/api';

const PAGE_TYPE_ORDINARY = 0;
const PAGE_TYPE_NEWS = 1;
const PAGE_TYPE_SPECIAL = 2;

function PageItem({
  item, pageToDelete, deletePage, setPageToDelete,
}) {
  return (
    <div className="col-sm-4 col-md-4" key={item.id}>
      {pageToDelete === item.id ? (
        <div className="block-flat danger-box">
          <div className="header">
            <h3>
              Är du säker?
              <br />
              <small>{item.title}</small>
            </h3>
          </div>
          <div className="content">
            <button type="button" className="btn btn-danger" onClick={() => deletePage(item.id)}>Ta bort</button>
            <button type="button" className="btn btn-default" onClick={() => setPageToDelete(0)}>Avbryt</button>
          </div>
        </div>
      ) : (
        <div className="block-flat">
          <div className="header">
            <h3>
              {item.title}
              {' '}
              <br />
              <small>{item.slug}</small>
            </h3>
          </div>
          <div className="content">
            <Link to={`/page/${item.id}/`} className="btn btn-default">Redigera</Link>
            <button type="button" className="btn btn-danger" onClick={() => setPageToDelete(item.id)}>Ta bort</button>
          </div>
        </div>
      )}
    </div>
  );
}

PageItem.propTypes = {
  item: PropTypes.object.isRequired,
  pageToDelete: PropTypes.number.isRequired,
  deletePage: PropTypes.func.isRequired,
  setPageToDelete: PropTypes.func.isRequired,
};

function Website() {
  const params = useParams();
  const [currentWebID, setCurrentWebID] = useState(null);
  const [pages, setPages] = useState();
  const [newsPosts, setNewsPosts] = useState();
  const [specialPosts, setSpecialPosts] = useState();
  const [name, setName] = useState();
  const [pageToDelete, setPageToDelete] = useState();

  const fetchPages = () => {
    API.fetch(`websites/${params.id}`).then((res) => {
      setPages(res.pages.filter((page) => page.type === PAGE_TYPE_ORDINARY));
      setNewsPosts(res.pages.filter((page) => page.type === PAGE_TYPE_NEWS));
      setSpecialPosts(res.pages.filter((page) => page.type === PAGE_TYPE_SPECIAL));
      setName(res.name);
      setCurrentWebID(res.id);
    });
  };

  const createNewPage = ({ type = PAGE_TYPE_ORDINARY, title, slug }) => {
    API.fetch('pages', 'POST', {
      website_id: params.id, type, title, slug,
    }).then(() => {
      fetchPages();
    });
  };

  const deletePage = (id) => {
    API.fetch(`pages/${id}`, 'DELETE', {}).then(() => {
      fetchPages();
    });
  };

  useEffect(() => {
    if (currentWebID !== Number(params.id)) {
      setPages(null);
      fetchPages();
    }
  }, [params]);

  const now = new Date();

  if (!pages) {
    return (
      <div className="cl-mcont">
        <Spinner />
      </div>
    );
  }

  return (
    <div>
      <div id="page-header" className="navbar navbar-default">
        <h2>
          Översikt för&nbsp;
          {name}
        </h2>
        <div className="pull-right">
          <Link to="/" className="btn btn-default">
            <i className="fa fa-arrow-left" />
            {' '}
            Tillbaka
          </Link>
          &nbsp;
          <Link to={`/website/${params.id}/settings`} className="btn btn-default">
            <i className="fa fa-wrench" />
            {' '}
            Inställningar
          </Link>
        </div>
      </div>
      <div className="cl-mcont">
        <div className="row flexwraps">
          <div className="inside-header">
            <h2>Sidor</h2>
            <div className="pull-right">
              <button
                type="button"
                className="btn btn-primary"
                href="#"
                onClick={() => createNewPage({
                  type: PAGE_TYPE_ORDINARY,
                })}
              >
                <i className="fa fa-plus" />
                {' '}
                Skapa ny sida
              </button>
            </div>
          </div>
          {pages && pages.map((item) => (
            <PageItem
              key={item.id}
              item={item}
              deletePage={deletePage}
              pageToDelete={pageToDelete}
              setPageToDelete={setPageToDelete}
            />
          ))}

          <div className="inside-header">
            <h2>Nyhetsinlägg</h2>
            <div className="pull-right">
              <button
                type="button"
                className="btn btn-primary"
                href="#"
                onClick={() => createNewPage({
                  type: PAGE_TYPE_NEWS,
                  title: 'Nytt nyhetsinlägg',
                  slug: `nyheter/${now.getFullYear()}-${now.getMonth()}-${now.getDate()}-${now.getHours()}-${now.getMinutes()}-${now.getSeconds()}`,
                })}
              >
                <i className="fa fa-plus" />
                {' '}
                Skapa nytt nyhetsinlägg
              </button>
            </div>
          </div>
          {newsPosts && newsPosts.map((item) => (
            <PageItem
              key={item.id}
              item={item}
              deletePage={deletePage}
              pageToDelete={pageToDelete}
              setPageToDelete={setPageToDelete}
            />
          ))}

          <div className="inside-header">
            <h2>Special och återanvändbart innehåll</h2>
            <div className="pull-right">
              <button
                type="button"
                className="btn btn-primary"
                href="#"
                onClick={() => createNewPage({
                  type: PAGE_TYPE_SPECIAL,
                  title: 'Nytt specialinnehåll',
                })}
              >
                <i className="fa fa-plus" />
                {' '}
                Skapa nytt specialinnehåll
              </button>
            </div>
          </div>
          {specialPosts && specialPosts.map((item) => (
            <PageItem
              key={item.id}
              item={item}
              deletePage={deletePage}
              pageToDelete={pageToDelete}
              setPageToDelete={setPageToDelete}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Website;
