import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Slider from 'rc-slider';
import { BlockSizeDropdown } from '../../components/BlockSizeDropdown';
import { BlockPlacingDropdown } from '../../components/BlockPlacingDropdown';
import BlockClassesField from '../../components/BlockClassesField';

export default class SpaceProperties extends Component {
  static defaultContent() {
    return {
      style: {
        height: 1,
        width: '100',
      },
    };
  }

  constructor(props) {
    super(props);

    this.handleHeightChange = this.handleHeightChange.bind(this);
    this.handleBlockSizeChange = this.handleBlockSizeChange.bind(this);
    this.handleBlockPlacingChange = this.handleBlockPlacingChange.bind(this);
  }

  handleHeightChange(height) {
    const diff = {
      content: {
        style: {
          height,
        },
      },
    };
    const { onChange } = this.props;
    onChange(diff);
  }

  handleBlockSizeChange(size) {
    const diff = {
      size,
    };
    const { onChange } = this.props;
    onChange(diff);
  }

  handleBlockPlacingChange(placing) {
    const diff = {
      placing,
    };
    const { onChange } = this.props;
    onChange(diff);
  }

  render() {
    const {
      content, size, placing, classes, onChange,
    } = this.props;

    return (
      <div>
        <BlockSizeDropdown defaultValue={size} onChange={this.handleBlockSizeChange} />
        <BlockPlacingDropdown defaultValue={placing} onChange={this.handleBlockPlacingChange} />
        <BlockClassesField defaultValue={classes} onChange={onChange} />

        <div className="field">
          <label htmlFor="prop-height">
            Mellanrum (
            {content.style.height}
            {' '}
            px)
          </label>
          <Slider
            id="prop-height"
            min={1}
            max={1000}
            defaultValue={content.style.height}
            step={1}
            onChange={this.handleHeightChange}
          />
        </div>
      </div>
    );
  }
}

SpaceProperties.propTypes = {
  onChange: PropTypes.func.isRequired,
  size: PropTypes.number.isRequired,
  placing: PropTypes.number.isRequired,
  classes: PropTypes.string.isRequired,
  content: PropTypes.shape({
    style: PropTypes.shape({
      height: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
};
