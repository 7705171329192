import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import Spinner from '../../components/spinner';

import API from '../../core/api';
import LogotypeManager from './logotype/logotypemanager';
import NavigationEditor from '../navigation-editor';

class SettingsComp extends Component {
  constructor(...args) {
    super(...args);

    this.state = {};

    this.fetchWebsite = this.fetchWebsite.bind(this);
    this.onChange = this.onChange.bind(this);
    this.saveWebsite = this.saveWebsite.bind(this);
  }

  componentDidMount() {
    this.fetchWebsite();
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ website: undefined });
    this.fetchWebsite(nextProps);
  }

  onChange(change) {
    this.setState(change);
  }

  fetchWebsite(newProps) {
    const { match } = this.props;
    const newPropsMatch = newProps && newProps.match;
    API.fetch(`websites/${(newPropsMatch || match).params.id}`).then((res) => this.setState({ website: res }));
  }

  saveWebsite(e) {
    e.preventDefault();

    this.setState({ website: null });

    const { website } = this.state;
    const request = {
      id: website.id,
      nav: website.nav,
    };

    API.fetch(`websites/${website.id}`, 'PUT', request).then(() => {
      this.fetchWebsite();
    });
  }

  render() {
    const { website } = this.state;
    if (!website) {
      return (
        <div className="cl-mcont">
          <Spinner />
        </div>
      );
    }

    return (
      <div>
        <div id="page-header" className="navbar navbar-default">
          <h2>
            Inställningar för&nbsp;
            {website.name}
          </h2>
          <div className="pull-right">
            <Link to={`/website/${website.id}`} className="btn btn-default">
              <i className="fa fa-arrow-left" />
              {' '}
              Tillbaka
            </Link>
            &nbsp;
            <button type="button" className="btn btn-primary" href="#" onClick={this.saveWebsite}>
              <i className="fa fa-cloud-upload" />
              Spara ändringar&nbsp;
            </button>
          </div>
        </div>
        <div className="cl-mcont">
          <div className="row">
            <div className="col-xs-12">
              <div className="block-flat" style={{ display: 'block', width: '100%', overflow: 'hidden' }}>
                <h3 className="no-margin-top">Logotyp</h3>
                <LogotypeManager website={website} onChange={this.onChange} />
              </div>
            </div>
          </div>

          <NavigationEditor website={website} onChange={this.onChange} />
        </div>
      </div>
    );
  }
}

SettingsComp.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
    }),
  }).isRequired,
};

// why do work now when I can do work later
function Settings() {
  const params = useParams();
  return <SettingsComp match={{ params }} />;
}

export default Settings;
