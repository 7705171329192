import Contactform from './contactform';
import Divider from './divider/divider';
import DividerProperties from './divider/divider-properties';
import Html from './html';
import Iframe from './iframe';
import Image from './image/image';
import ImageProperties from './image/image-properties';
import Paragraph from './paragraph/paragraph';
import ParagraphProperties from './paragraph/paragraph-properties';
import Title from './title/title';
import TitleProperties from './title/title-properties';
import Wysiwyg from './wysiwyg';
import WysiwygProperties from './wysiwyg/wysiwyg-properties';
import CountDown from './countDown/countDown';
import CountDownProperties from './countDown/countDown-properties';
import Space from './space/space';
import SpaceProperties from './space/space-properties';
import List from './list/list';
import ListProperties from './list/list-properties';
import Table from './table/table';
import TableProperties from './table/table-properties';
import Youtube from './youtube/youtube';
import YoutubeProperties from './youtube/youtube-properties';
import Instagram from './instagram/instagram';
import InstagramProperties from './instagram/instagram-properties';
import Newslist from './newslist/newslist';
import NewslistProperties from './newslist/newslist-properties';
import DefaultProperties from './default/default-properties';

const register = {};

function registerBlock(type, icon, title, displayComp, propertiesComp) {
  register[type] = {
    type,
    title,
    icon,
    display: displayComp,
    properties: propertiesComp || DefaultProperties,
  };
}

registerBlock('contactform', 'fa-pencil-square-o', 'Kontaktforumlär', Contactform, null);
registerBlock('title', 'fa-header', 'Rubrik', Title, TitleProperties);
registerBlock('wysiwyg', 'fa-file-text-o', 'Text', Wysiwyg, WysiwygProperties);
registerBlock('paragraph', 'fa-paragraph', 'Paragraf', Paragraph, ParagraphProperties);
registerBlock('image', 'fa-file-image-o', 'Bilder', Image, ImageProperties);
registerBlock('divider', 'fa-minus', 'Avdelare', Divider, DividerProperties);
registerBlock('space', 'fa-arrows-v', 'Mellanrum', Space, SpaceProperties);
registerBlock('html', 'fa-file-code-o', 'HTML', Html, null);
registerBlock('iframe', 'fa-picture-o', 'Inbäddad länk', Iframe, null);
registerBlock('countDown', 'far fa-hourglass', 'Timer', CountDown, CountDownProperties);
registerBlock('list', 'fa-list', 'Lista', List, ListProperties);
registerBlock('table', 'fa-table', 'Tabell', Table, TableProperties);
registerBlock('youtube', 'fa-youtube', 'Youtube-video', Youtube, YoutubeProperties);
registerBlock('instagram', 'fa-instagram', 'Instagram-feed', Instagram, InstagramProperties);
registerBlock('newslist', 'fa-newspaper-o', 'Senaste nyhetsinlägg', Newslist, NewslistProperties);

export function resolveBlock(type) {
  const block = register[type];
  if (!block) {
    console.error(`No block by the type '${type}', forgott to add it to BlockRegister?`); // eslint-disable-line
  }
  return block;
}

export function resolveDisplayBlock(type) {
  const block = resolveBlock(type);
  return block.display;
}

export function resolvePropertiesBlock(type) {
  const block = resolveBlock(type);
  return block.properties;
}

export function getBlockTypes() {
  return Object.keys(register).map((key) => register[key]);
}
