import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import { ChromePicker } from 'react-color';
import Slider from 'rc-slider';
import { BlockSizeDropdown } from '../../components/BlockSizeDropdown';
import { BlockPlacingDropdown } from '../../components/BlockPlacingDropdown';
import BlockClassesField from '../../components/BlockClassesField';

export default class TableProperties extends Component {
  static defaultContent() {
    return {
      tableHead: '4\n5\n6',
      tableRow: '1\n2\n3',
      style: {
        width: 1,
        align: 'center',
        fontSize: 1.5,
        lineHeight: 1,
      },
    };
  }

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleSizeChange = this.handleSizeChange.bind(this);
    this.handleCssChange = this.handleCssChange.bind(this);
    this.handleWidthChange = this.handleWidthChange.bind(this);
    this.handleAlign = this.handleAlign.bind(this);
    this.handleBlockSizeChange = this.handleBlockSizeChange.bind(this);
    this.handleBlockPlacingChange = this.handleBlockPlacingChange.bind(this);
  }

  handleChange(e, key) {
    const diff = {
      content: {
        [key]: e.target.value,
      },
    };
    const { onChange } = this.props;
    onChange(diff);
  }

  handleCssChange(e, key) {
    const diff = {
      content: {
        style: {
          [key]: e.target.value,
        },
      },
    };
    const { onChange } = this.props;
    onChange(diff);
  }

  handleSizeChange(fontSize) {
    const diff = {
      content: {
        style: {
          fontSize,
        },
      },
    };
    const { onChange } = this.props;
    onChange(diff);
  }

  handleWidthChange(width) {
    const diff = {
      content: {
        style: {
          width,
        },
      },
    };
    const { onChange } = this.props;
    onChange(diff);
  }

  handleAlign(e) {
    const diff = {
      content: {
        style: {
          align: e.taget.value,
        },
      },
    };
    const { onChange } = this.props;
    onChange(diff);
  }

  handleBlockSizeChange(size) {
    const diff = {
      size,
    };
    const { onChange } = this.props;
    onChange(diff);
  }

  handleBlockPlacingChange(placing) {
    const diff = {
      placing,
    };
    const { onChange } = this.props;
    onChange(diff);
  }

  render() {
    const {
      content, size, placing, classes, onChange,
    } = this.props;

    const { tableHead, tableRow, style } = content;

    return (
      <div>
        <BlockSizeDropdown defaultValue={size} onChange={this.handleBlockSizeChange} />
        <BlockPlacingDropdown defaultValue={placing} onChange={this.handleBlockPlacingChange} />
        <BlockClassesField classes={classes} onChange={onChange} />

        <div className="field">
          <label htmlFor="content-text">Tabell huvud</label>
          <textarea
            id="content-text"
            type="text"
            className="form-control"
            value={tableHead}
            onChange={(e) => this.handleChange(e, 'tableHead')}
            rows={7}
            cols={7}
          />
          <i>En cell per rad, skippa en rad för att starta en ny rad.</i>
        </div>

        <div className="field">
          <label htmlFor="content-text">Tabell innehåll</label>
          <textarea
            id="content-text"
            type="text"
            value={tableRow}
            className="form-control"
            onChange={(e) => this.handleChange(e, 'tableRow')}
            rows={0}
            cols={7}
          />
          <i>En cell per rad, skippa en rad för att starta en ny rad.</i>
        </div>

        <div className="field">
          <label htmlFor="content-text">Tabell justering</label>
          <select
            id="prop-text-align"
            value={style.align}
            className="form-control"
            onChange={(e) => this.handleCssChange(e, 'align')}
          >
            <option value="left">Vänster</option>
            <option value="center">Centrerad</option>
            <option value="right">Höger</option>
          </select>
        </div>

        {/* <div className="field">
          <label htmlFor="content-text">Text storlek</label>
          <Slider
            id="prop-font-size"
            min={1}
            max={10}
            value={style.fontSize}
            step={0.1}
            onChange={this.handleSizeChange}
          />
        </div> */}

        <div className="field">
          <label htmlFor="content-text">
            Tabellbredd:
            {style.width}
            %
          </label>
          <Slider
            id="prop-width"
            min={1}
            max={100}
            value={style.width}
            step={1}
            onChange={this.handleWidthChange}
          />
        </div>
      </div>
    );
  }
}

TableProperties.propTypes = {
  onChange: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  placing: PropTypes.number.isRequired,
  classes: PropTypes.string.isRequired,
  content: PropTypes.shape({
    tableHead: PropTypes.string.isRequired,
    tableRow: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    style: PropTypes.shape({
      fontSize: PropTypes.number.isRequired,
      width: PropTypes.number.isRequired,
      align: PropTypes.string.isRequired,
    }),
  }).isRequired,
};
