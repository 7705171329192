import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SYSTEM_FONTS, GOOGLE_FONTS } from './fonts';

const fonts = SYSTEM_FONTS.concat(GOOGLE_FONTS);

const renderFontOption = (font) => {
  const ix = fonts.indexOf(font);
  return (<option key={ix} value={ix}>{font.name}</option>);
};

export default class FontPicker extends Component {
  constructor(props) {
    super(props);

    this.handleFontChange = this.handleFontChange.bind(this);
  }

  handleFontChange(event) {
    const ix = event.target.value;
    const font = fonts[ix];
    const { onChange } = this.props;
    onChange(font);
  }

  render() {
    const { fontFamily } = this.props;
    const selectedIx = fonts.findIndex((font) => (
      font.source === fontFamily.source && font.value === fontFamily.value
    ));

    return (
      <select
        onChange={this.handleFontChange}
        value={selectedIx}
        className="form-control"
      >
        {SYSTEM_FONTS.map(renderFontOption)}
        <optgroup label="Google Fonts">
          {GOOGLE_FONTS.map(renderFontOption)}
        </optgroup>
      </select>
    );
  }
}

FontPicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  fontFamily: PropTypes.shape({
    source: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
};
