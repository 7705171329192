import React from 'react';
import PropTypes from 'prop-types';
import { BlockSizeDropdown } from '../../components/BlockSizeDropdown';
import { BlockPlacingDropdown } from '../../components/BlockPlacingDropdown';
import BlockClassesField from '../../components/BlockClassesField';

function DefaultProperties({
  onChange, size, placing, classes,
}) {
  const handleBlockSizeChange = (newSize) => {
    const diff = {
      size: newSize,
    };
    onChange(diff);
  };

  const handleBlockPlacingChange = (newPlacing) => {
    const diff = {
      placing: newPlacing,
    };
    onChange(diff);
  };

  return (
    <div>
      <BlockSizeDropdown defaultValue={size} onChange={handleBlockSizeChange} />
      <BlockPlacingDropdown defaultValue={placing} onChange={handleBlockPlacingChange} />
      <BlockClassesField defaultValue={classes} onChange={onChange} />
    </div>
  );
}

DefaultProperties.propTypes = {
  onChange: PropTypes.func.isRequired,
  size: PropTypes.string.isRequired,
  placing: PropTypes.string.isRequired,
  classes: PropTypes.string.isRequired,
};

export default DefaultProperties;
