import React from 'react';
import PropTypes from 'prop-types';
import reactCSS from 'reactcss';

function getMargin(align, reverse = false) {
  let names = ['left', 'center', 'right'];
  const values = ['0', 'auto', 'auto'];

  if (reverse) {
    names = names.reverse();
  }

  const ix = names.indexOf(align);
  return values[ix];
}

function Divider({ item }) {
  const { content } = item;

  const styles = reactCSS({
    default: {
      divider: {
        borderWidth: `${content.style.borderWidth}px`,
        borderColor: `rgba(
            ${content.style.borderColor.r},
            ${content.style.borderColor.g},
            ${content.style.borderColor.b},
            ${content.style.borderColor.a}
          )`,
        width: `${content.style.width}%`,
        marginLeft: getMargin(content.style.align),
        marginRight: getMargin(content.style.align, true),
        textAlign: content.style.align,
      },
    },
  });

  if (typeof item === 'undefined') {
    return <hr />;
  }

  return (
    <hr style={styles.divider} />
  );
}

Divider.propTypes = {
  item: PropTypes.string.isRequired,
};

export default Divider;
