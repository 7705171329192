import React from 'react';
import reactCSS from 'reactcss';

export default class Text extends React.Component {
  static updateFontLink(id, content) {
    const { style } = content;
    const { fontFamily } = style;

    if (fontFamily.source === 'google') {
      const linkId = `google-font-link-${id}`;
      let link = document.getElementById(linkId);

      if (!link) {
        link = document.createElement('link');
        link.id = linkId;
        link.rel = 'stylesheet';
        document.head.appendChild(link);
      }

      const encoded = fontFamily.value.replace(/ /g, '+');
      link.href = `https://fonts.googleapis.com/css?family=${encoded}`;
    }
  }

  static getStyle(content) {
    const { style } = content;

    return reactCSS({
      default: {
        text: {
          color: `rgba(
            ${style.color.r},
            ${style.color.g},
            ${style.color.b},
            ${style.color.a}
            )`,
          fontFamily: style.fontFamily.value,
          fontSize: `${style.fontSize}em`,
          textAlign: style.textAlign,
          letterSpacing: `${style.letterSpacing}em`,
          fontWeight: style.fontWeight,
        },
      },
    });
  }

  render() {
    return null;
  }
}
