import React from 'react';
import PropTypes from 'prop-types';

import { ControlledEditor as MonacoEditor } from '@monaco-editor/react';

export default class Html extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.editorDidMount = this.editorDidMount.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  handleResize() {
    this.editor.layout();
  }

  onChange(e, content) {
    const { item, onChange } = this.props;

    onChange({
      ...item,
      content,
    });
  }

  editorDidMount(_, editor) {
    this.editor = editor;
  }

  render() {
    const { item } = this.props;

    if (typeof item === 'undefined') {
      return <div />;
    }

    const options = {
      selectOnLineNumbers: true,
    };

    return (
      <div style={{ marginLeft: '-20px', marginRight: '-20px' }}>
        <MonacoEditor
          height="400px"
          language="html"
          value={item.content}
          options={options}
          onChange={this.onChange}
          editorDidMount={this.editorDidMount}
        />
      </div>
    );
  }
}

Html.propTypes = {
  item: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};
