import React, { useState } from 'react';
import PropTypes from 'prop-types';

export default function BlockClassesField({ defaultValue = '', onChange }) {
  const [visible, setVisible] = useState(false);

  const handleChange = (event) => {
    const diff = {
      classes: event.target.value,
    };
    onChange(diff);
  };

  return (
    <div className="field">
      <label htmlFor="pageblocks-classes">
        Extra HTML-klasser (avancerat)
        {' '}
        <input
          style={{
            display: 'inline-block',
            width: 'auto',
            marginLeft: '5px',
            marginTop: '5px',
            verticalAlign: 'sub',
          }}
          type="checkbox"
          checked={visible}
          onChange={() => setVisible(!visible)}
        />
      </label>

      <input
        id="pageblocks-classes"
        className={`form-control ${visible ? '' : 'hidden'}`}
        type="text"
        value={defaultValue}
        onChange={handleChange}
      />
    </div>
  );
}

BlockClassesField.propTypes = {
  defaultValue: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
