import React from 'react';
import PropTypes from 'prop-types';
import Text from '../abstract/text/text';

export default class Title extends Text {
  render() {
    const { item } = this.props;
    const { id, content } = item;

    Title.updateFontLink(id, content);

    const styles = Title.getStyle(content);

    if (typeof item === 'undefined') {
      return <div />;
    }

    return (
      <h1 style={styles.text}>
        {item.content.text}
      </h1>
    );
  }
}

Title.propTypes = {
  item: PropTypes.string.isRequired,
};
