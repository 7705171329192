import React, {
  useRef, useState, useEffect, useCallback,
} from 'react';
import ReactDOM from 'react-dom';

import './propertypanel.css';

let ppContainerElement;

const usePortal = (el) => {
  const [portal, setPortal] = useState({
    render: () => null,
    remove: () => null,
  });

  const createPortal = useCallback((elem) => {
    if (elem) {
      const Portal = ({ children }) => ReactDOM.createPortal(children, elem);
      const remove = () => ReactDOM.unmountComponentAtNode(elem);
      return { render: Portal, remove };
    }
    return { render: () => (null), remove: () => (null) };
  }, []);

  useEffect(() => {
    if (el) portal.remove();
    const newPortal = createPortal(el);
    setPortal(newPortal);
    return () => newPortal.remove(el);
  }, [el]);

  return portal.render;
};

export function PropertyPanelContainer() {
  ppContainerElement = useRef();

  return (
    <div>
      <div ref={ppContainerElement} />
    </div>
  );
}

// eslint-disable-next-line react/prop-types
export function PropertyPanel({ children }) {
  const Portal = usePortal(ppContainerElement && ppContainerElement.current);

  useEffect(() => {
    if (children && document.getElementById('sidebar-properties-button').className.search('btn-active') === -1) {
      document.getElementById('sidebar-properties-button').click();
    }
  }, [children]);

  return (<Portal>{children || (<h4 style={{ color: 'white', padding: '10px' }}>Detta block har inga speciella egenskaper</h4>)}</Portal>);
}
