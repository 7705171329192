import React from 'react';
import PropTypes from 'prop-types';
import reactCSS from 'reactcss';

function Youtube({ item }) {
  const { content } = item;

  const styles = reactCSS({
    default: {
      parent: {
        textAlign: `${content.style.align}`,
      },
      iframe: {
        height: `${content.style.height}px`,
        width: `${content.style.width}%`,
      },
    },
  });

  if (typeof item === 'undefined') {
    return <div />;
  }

  return (
    <div className="iframeParent" style={styles.parent}>
      <iframe
        title="clip"
        style={styles.iframe}
        src={content.url}
      />
    </div>
  );
}

Youtube.propTypes = {
  item: PropTypes.object.isRequired,
};

export default Youtube;
