import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import { ChromePicker } from 'react-color';
import Slider from 'rc-slider';
import { BlockSizeDropdown } from '../../components/BlockSizeDropdown';
import { BlockPlacingDropdown } from '../../components/BlockPlacingDropdown';
import BlockClassesField from '../../components/BlockClassesField';

export default class ListProperties extends Component {
  static defaultContent() {
    return {
      title: '',
      list: '1\n2\n3',
      style: {
        width: 100,
        align: 'left',
        listStyleType: 'none',
        fontSize: 1.5,
        lineHeight: 1,
      },
    };
  }

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleSizeChange = this.handleSizeChange.bind(this);
    this.handleCssChange = this.handleCssChange.bind(this);
    this.handleWidthChange = this.handleWidthChange.bind(this);
    this.handleAlign = this.handleAlign.bind(this);
    this.handleBlockSizeChange = this.handleBlockSizeChange.bind(this);
    this.handleBlockPlacingChange = this.handleBlockPlacingChange.bind(this);
  }

  handleChange(e, key) {
    const diff = {
      content: {
        [key]: e.target.value,
      },
    };
    const { onChange } = this.props;
    onChange(diff);
  }

  handleCssChange(e, key) {
    const diff = {
      content: {
        style: {
          [key]: e.target.value,
        },
      },
    };
    const { onChange } = this.props;
    onChange(diff);
  }

  handleSizeChange(fontSize) {
    const diff = {
      content: {
        style: {
          fontSize,
        },
      },
    };
    const { onChange } = this.props;
    onChange(diff);
  }

  handleWidthChange(width) {
    const diff = {
      content: {
        style: {
          width,
        },
      },
    };
    const { onChange } = this.props;
    onChange(diff);
  }

  handleAlign(e) {
    const diff = {
      content: {
        style: {
          align: e.taget.value,
        },
      },
    };
    const { onChange } = this.props;
    onChange(diff);
  }

  handleBlockSizeChange(size) {
    const diff = {
      size,
    };
    const { onChange } = this.props;
    onChange(diff);
  }

  handleBlockPlacingChange(placing) {
    const diff = {
      placing,
    };
    const { onChange } = this.props;
    onChange(diff);
  }

  render(TextType = 'input') {
    const {
      content, size, placing, classes, onChange,
    } = this.props;

    return (
      <div>
        <BlockSizeDropdown defaultValue={size} onChange={this.handleBlockSizeChange} />
        <BlockPlacingDropdown defaultValue={placing} onChange={this.handleBlockPlacingChange} />
        <BlockClassesField defaultValue={classes} onChange={onChange} />

        <div className="field">
          <label htmlFor="content-text">Rubrik</label>
          <TextType
            id="content-text"
            className="form-control"
            type="text"
            value={content.title}
            onChange={(e) => this.handleChange(e, 'title')}
          />
        </div>

        <div className="field">
          <label htmlFor="content-text">Listinnehåll</label>
          <textarea
            id="content-text"
            className="form-control"
            type="text"
            value={content.list}
            onChange={(e) => this.handleChange(e, 'list')}
            rows={7}
            cols={7}
          />
          <i>Varje ny rad är en ny rad i listan.</i>
        </div>

        <div className="field">
          <label htmlFor="prop-text-align">Text justering</label>
          <select
            id="prop-text-align"
            className="form-control"
            value={content.style.align}
            onChange={(e) => this.handleCssChange(e, 'align')}
          >
            <option value="left">Vänster</option>
            <option value="center">Centrerad</option>
            <option value="right">Höger</option>
          </select>
        </div>

        <div className="field">
          <label htmlFor="prop-font-size">Text storlek</label>
          <Slider
            id="prop-font-size"
            min={1}
            max={10}
            value={content.style.fontSize}
            step={0.1}
            onChange={this.handleSizeChange}
          />
        </div>

        <div className="field">
          <label htmlFor="prop-width">List bredd</label>
          <Slider
            id="prop-width"
            min={1}
            max={100}
            value={content.style.width}
            step={1}
            onChange={this.handleWidthChange}
          />
        </div>

        <div className="field">
          <label htmlFor="prop-list-style">List stil</label>
          <select
            id="prop-list-style"
            className="form-control"
            value={content.style.listStyleType}
            onChange={(e) => this.handleCssChange(e, 'listStyleType')}
          >
            <option value="none">Ingen stil</option>
            <option value="square">Fyrkant</option>
            <option value="circle">Cirkel</option>
          </select>
        </div>
      </div>
    );
  }
}

ListProperties.propTypes = {
  onChange: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  placing: PropTypes.number.isRequired,
  classes: PropTypes.string.isRequired,
  content: PropTypes.shape({
    list: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    style: PropTypes.shape({
      listStyleType: PropTypes.string.isRequired,
      width: PropTypes.number.isRequired,
      fontSize: PropTypes.number.isRequired,
      align: PropTypes.string.isRequired,
    }),
  }).isRequired,
};
