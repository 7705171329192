import React, { useState } from 'react';
import PropTypes from 'prop-types';

function MetaInput({
  edit, index, item, prop, placeholder,
}) {
  return (
    <td>
      <input
        type="text"
        placeholder={placeholder}
        value={item[prop]}
        onChange={(e) => edit(e, index, prop)}
        className="form-control"
      />
    </td>
  );
}

MetaInput.propTypes = {
  edit: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  item: PropTypes.string.isRequired,
  prop: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
};

function MetaSelect({
  edit, index, item, prop,
}) {
  return (
    <td>
      <select
        onChange={(e) => edit(e, index, prop)}
        value={item.type}
        className="form-control"
      >
        <option value="name">name</option>
        <option value="property">property</option>
      </select>
    </td>
  );
}

MetaSelect.propTypes = {
  edit: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  item: PropTypes.string.isRequired,
  prop: PropTypes.string.isRequired,
};

export default function MetaEditor({ meta: metainput, onChange }) {
  const [deleteRowId, setDeleteRowId] = useState(null);

  let meta;
  const defaultMeta = [
    { type: 'name', name: 'description', content: '' },
    { type: 'name', name: 'keywords', content: '' },
    { type: 'name', name: 'author', content: '' },
  ];

  try {
    meta = JSON.parse(metainput) || defaultMeta;
  } catch (e) {
    meta = defaultMeta;
  }

  const edit = (e, index, name) => {
    meta[index][name] = e.target.value;
    onChange(JSON.stringify(meta), 'meta');
  };

  const addRow = (e) => {
    meta.push({ type: 'property', name: '', content: '' });
    onChange(JSON.stringify(meta), 'meta');
    e.preventDefault();
  };

  const deleteRow = (index) => {
    meta = meta.filter((v, i) => i !== index);
    onChange(JSON.stringify(meta), 'meta');
  };

  return (
    <>
      <p>
        <strong>Meta-redigerare</strong>
        <br />
        Om du vill använda OpenGraph-meta, använd typen property.
        {' '}
        <a href="https://ogp.me/" rel="noopener noreferrer" target="_blank">Läs mer on OpenGraph här.</a>
      </p>
      <table className="table table-striped">
        <thead>
          <tr>
            <th>
              <i className="fa fa-times" />
              &nbsp;
            </th>
            <th><strong>Typ</strong></th>
            <th><strong>Meta-namn</strong></th>
            <th><strong>Värde</strong></th>
          </tr>
        </thead>
        <tbody>
          {meta.map((item, index) => (
            (deleteRowId === index ? (
              <tr>
                <td />
                <td>
                  Är du säker?
                </td>
                <td>
                  <button type="button" style={{ padding: '7px' }} onClick={() => deleteRow(index)} className="btn btn-danger">
                    Ta bort
                  </button>
                  <button type="button" style={{ padding: '7px' }} onClick={() => setDeleteRowId(null)} className="btn btn-default">
                    Avbryt
                  </button>
                </td>
                <td />
              </tr>
            ) : (
              <tr>
                <td style={{ width: '1%' }}>
                  <button type="button" style={{ padding: '7px' }} onClick={() => setDeleteRowId(index)} className="btn btn-danger">
                    <i className="fa fa-times" />
                  </button>
                </td>
                <MetaSelect edit={edit} index={index} item={item} prop="type" />
                <MetaInput edit={edit} index={index} item={item} prop="name" placeholder="Meta-namn" />
                <MetaInput edit={edit} index={index} item={item} prop="content" placeholder="Värde" />
              </tr>
            ))
          ))}
        </tbody>
      </table>

      <button type="button" className="btn btn-default" onClick={addRow}>
        <i className="fa fa-plus" />
        {' '}
        Lägg till rad
      </button>
    </>
  );
}

MetaEditor.propTypes = {
  meta: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
