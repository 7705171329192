import React from 'react';
import PropTypes from 'prop-types';
import { BlockSizeDropdown } from '../../components/BlockSizeDropdown';
import { BlockPlacingDropdown } from '../../components/BlockPlacingDropdown';
import BlockClassesField from '../../components/BlockClassesField';

function InstagramProperties({
  onChange, size, placing, content, classes,
}) {
  const handleUsernameChange = (event) => {
    onChange({
      content: {
        username: event.target.value,
      },
    });
  };

  const handleBlockSizeChange = (newSize) => {
    const diff = {
      size: newSize,
    };
    onChange(diff);
  };

  const handleBlockPlacingChange = (newPlacing) => {
    const diff = {
      placing: newPlacing,
    };
    onChange(diff);
  };

  const { username } = content;

  return (
    <div>
      <BlockSizeDropdown defaultValue={size} onChange={handleBlockSizeChange} />
      <BlockPlacingDropdown defaultValue={placing} onChange={handleBlockPlacingChange} />
      <BlockClassesField defaultValue={classes} onChange={onChange} />

      <div className="field">
        <label htmlFor="prop-username">Namn på Instagram-användare</label>
        <input
          id="prop-username"
          className="form-control"
          type="text"
          placeholder="webb46.se"
          value={username}
          onChange={handleUsernameChange}
        />
      </div>
    </div>
  );
}

InstagramProperties.defaultContent = () => ({
  username: '',
});

InstagramProperties.propTypes = {
  onChange: PropTypes.func.isRequired,
  size: PropTypes.number.isRequired,
  placing: PropTypes.number.isRequired,
  classes: PropTypes.string.isRequired,
  content: PropTypes.shape({
    username: PropTypes.string.isRequired,
  }).isRequired,
};

export default InstagramProperties;
