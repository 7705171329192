import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from '../../components/dropdown/Dropdown';

export const BlockSize = Object.freeze({
  Full: '0',
  Wide: '1',
  Medium: '2',
  Thin: '3',
});

export const blockSizeOptions = [
  { name: 'Fullbredd (100%)', value: BlockSize.Full },
  { name: 'Bred (~70%)', value: BlockSize.Wide },
  { name: 'Mellan (~50%)', value: BlockSize.Medium },
  { name: 'Smal (~30%)', value: BlockSize.Thin },
];

export function BlockSizeDropdown({ defaultValue = BlockSize.Full, onChange }) {
  return (
    <Dropdown
      name="Blockstorlek"
      id="prop-block-size"
      options={blockSizeOptions}
      defaultValue={defaultValue}
      onChange={onChange}
    />
  );
}

BlockSizeDropdown.propTypes = {
  defaultValue: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
