import React from 'react';
import PropTypes from 'prop-types';

function Newslist({ item }) {
  if (typeof item === 'undefined') {
    return <div />;
  }

  return (
    <strong>
      Detta block visar de
      {' '}
      {item.content.numberOfItems}
      {' '}
      senaste nyhetsinläggen.
    </strong>
  );
}

Newslist.propTypes = {
  item: PropTypes.object.isRequired,
};

export default Newslist;
