import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Spinner from '../components/spinner';

import API from '../core/api';
import ccc from '../core/classes';
import store from '../core/store';

class Login extends Component {
  constructor(...args) {
    super(...args);
    this.tryLogin = this.tryLogin.bind(this);
    const { onSuccessfulLogin, onLoginFailed } = this.props;

    this.state = { disabled: false };

    if (store && store.autologin && store.autologin.token_type) {
      this.state = { disabled: true };

      API.set(store.autologin);

      API.fetch('auth/me').then(() => {
        this.setState({
          error: '',
          disabled: false,
        });

        onSuccessfulLogin(store.autologin);
      }).catch(() => {
        this.setState({
          error: 'Din session har timeat ut',
          disabled: false,
        });

        onLoginFailed();
      });
    }
  }

  tryLogin(e) {
    e.preventDefault();

    const email = this.email.value;
    const password = this.password.value;
    const { onSuccessfulLogin } = this.props;

    this.setState({
      disabled: true,
    });

    API.login({ email, password }).then((result) => {
      onSuccessfulLogin(result);
      store.autologin = result;
    }).catch(() => {
      this.setState({
        error: 'Fel användarnamn eller lösenord',
        disabled: false,
      });
    });
  }

  render() {
    const { disabled, error } = this.state;
    return (
      <div id="cl-wrapper" className="login-container">
        <div className="middle-login">
          <div className="block-flat">
            <div className="header" style={{ textAlign: 'center', padding: '20px 0' }}>
              <img src={`${process.env.PUBLIC_URL}/w46_big_logo_payoff_white.png`} alt="Logga in på Webb46 Kontrollpanel" className="img-responsive" style={{ maxHeight: '50px', display: 'inline-block' }} />
            </div>
            <div>
              {disabled ? <Spinner /> : (
                <fieldset disabled={this.state && disabled}>
                  <form className={ccc({ 'form-horizontal': true, 'has-error': this.state && error })} action="#">
                    <div className="content">
                      <h4 className="title">Använd din e-mailadress och lösenord för att logga in.</h4>
                      <div className="form-group">
                        <div className="col-sm-12">
                          <div className="input-group">
                            <span className="input-group-addon"><i className="fa fa-envelope" /></span>
                            <input type="text" placeholder="E-mailadress" id="email" ref={(c) => { this.email = c; }} className="form-control" />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="col-sm-12">
                          <div className="input-group">
                            <span className="input-group-addon"><i className="fa fa-lock" style={{ width: '14px', textAlign: 'center' }} /></span>
                            <input type="password" placeholder="Lösenord" id="password" ref={(c) => { this.password = c; }} className="form-control" />
                          </div>
                        </div>
                      </div>
                      <div className={ccc({
                        alert: true,
                        'alert-warning': true,
                        'alert-white': true,
                        rounded: true,
                        hidden: !(this.state && error),
                      })}
                      >
                        <div className="icon"><i className="fa fa-warning" /></div>
                        <strong>Ojdå!</strong>
                        {' '}
                        {this.state && error}
                        .
                      </div>
                    </div>
                    <div className="foot">
                      <button type="submit" className="btn btn-primary" onClick={this.tryLogin}>Logga in</button>
                    </div>
                  </form>
                </fieldset>
              )}
            </div>
          </div>
          <div className="text-center out-links">
            &copy;
            {(new Date()).getUTCFullYear()}
            {' '}
            Webb46
          </div>
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  onSuccessfulLogin: PropTypes.func.isRequired,
  onLoginFailed: PropTypes.func.isRequired,
};

export default Login;
