import React, { useState } from 'react';
import {
  BrowserRouter, Routes, Route, Navigate,
} from 'react-router-dom';
import PropTypes from 'prop-types';

import Sidebar from './components/sidebar';

import Login from './pages/login';
import Home from './pages/home';
import Website from './pages/website';
import Settings from './pages/settings/settings';
import Page from './pages/page/page';

import API from './core/api';
import store from './core/store';

function Logout({ setAppState }) {
  API.logout();
  store.autologin = {};
  setAppState({ loggedIn: false });
  return (
    <>
      <h1 style={{ color: 'white' }}>
        Logging out...
      </h1>
      <Navigate to="/" push />
    </>
  );
}

Logout.propTypes = {
  setAppState: PropTypes.func,
};

Logout.defaultProps = {
  setAppState: null,
};

function App() {
  const [appState, setAppState] = useState({});

  if (!appState || !appState.loggedIn) {
    return (
      <Login
        onSuccessfulLogin={(authData) => setAppState({
          loggedIn: true,
          authData,
        })}
        onLoginFailed={() => setAppState({ loggedIn: false, authData: {} })}
      />
    );
  }

  return (
    <div>
      <BrowserRouter>
        <div id="cl-wrapper" style={{ opacity: 1, marginLeft: 0 }}>
          <Sidebar />
          <div className="container-fluid" id="pcont">
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route
                exact
                path="/logout"
                element={<Logout setAppState={setAppState} />}
              />
              <Route exact path="/website/:id" element={<Website />} />
              <Route exact path="/website/:id/settings" element={<Settings />} />
              <Route path="/page/:id" element={<Page />} />
              <Route render={() => <h1 style={{ color: 'white' }}>404</h1>} authData={appState.authData} />
            </Routes>
          </div>
        </div>
      </BrowserRouter>
      <div
        className="progress progress-striped active"
        style={{
          display: appState && appState.loading ? 'block' : 'none',
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 1000,
          margin: 0,
        }}
      >
        <div className="progress-bar" style={{ width: '100%' }} />
      </div>
    </div>
  );
}

export default App;
