import React from 'react';
import PropTypes from 'prop-types';
import { BlockSizeDropdown } from '../../components/BlockSizeDropdown';
import { BlockPlacingDropdown } from '../../components/BlockPlacingDropdown';
import Dropdown from '../../../components/dropdown/Dropdown';
import BlockClassesField from '../../components/BlockClassesField';

export const WysiStyle = Object.freeze({
  Normal: '0',
  AlertPrimary: '1',
  AlertSecondary: '2',
  AlertSuccess: '3',
  AlertDanger: '4',
  AlertWarning: '5',
  Blockquote: '6',
  Card: '7',
});

export const wysiStyleOpts = [
  { name: 'Normalt', value: WysiStyle.Normal },
  { name: 'Puff (primär)', value: WysiStyle.AlertPrimary },
  { name: 'Puff (sekundär)', value: WysiStyle.AlertSecondary },
  { name: 'Puff (lyckad, grön)', value: WysiStyle.AlertSuccess },
  { name: 'Puff (fara, röd)', value: WysiStyle.AlertDanger },
  { name: 'Puff (varning, gul)', value: WysiStyle.AlertWarning },
  { name: 'Blockcitat', value: WysiStyle.Blockquote },
  { name: 'Kort', value: WysiStyle.Card },
];

function WysiwygProperties({
  onChange, content, size, placing, classes,
}) {
  const handleBlockSizeChange = (newSize) => {
    const diff = {
      size: newSize,
    };
    onChange(diff);
  };

  const handleBlockPlacingChange = (newPlacing) => {
    const diff = {
      placing: newPlacing,
    };
    onChange(diff);
  };

  const handleStyleChange = (newStyle) => {
    const diff = {
      content: {
        ...content,
        style: newStyle,
      },
    };
    onChange(diff);
  };

  return (
    <div>
      <Dropdown
        name="Blockstil"
        id="prop-wysi-style"
        options={wysiStyleOpts}
        defaultValue={content.style || WysiStyle.Normal}
        onChange={handleStyleChange}
      />
      <BlockSizeDropdown defaultValue={size} onChange={handleBlockSizeChange} />
      <BlockPlacingDropdown defaultValue={placing} onChange={handleBlockPlacingChange} />
      <BlockClassesField defaultValue={classes} onChange={onChange} />
    </div>
  );
}

WysiwygProperties.propTypes = {
  onChange: PropTypes.func.isRequired,
  size: PropTypes.number.isRequired,
  placing: PropTypes.number.isRequired,
  classes: PropTypes.string.isRequired,
  content: PropTypes.object.isRequired,
};

export default WysiwygProperties;
